.data-table {
    width: 100%;
    margin-bottom: 30px;
    border-radius: 5px;
    display: inline-block;
}

.full-table {
    width: 100%;
    min-width: 700px;
    margin: 0;
    border-radius: 5px;
    background-color: #fff;
    border-collapse: separate;
    border-spacing: 0;
}

.full-table th,
.full-table td {
    padding: 11px 15px;
    font-size: 13px;
    vertical-align: middle;
    line-height: normal;
    font-weight: 400;
    color: #000;
    border-top: 0;
    height: 52px;
}

.full-table th {
    color: #000;
    font-weight: 600;
    font-size: 14px;
    vertical-align: middle;
    height: auto;
}

.full-table tr td {
    border: 1px solid #d5d5d5;
    border-right: 0;
    border-top: 0;
}

.full-table tr td:last-child {
    border-right: 1px solid #d5d5d5;
}
.full-table tr td.add-inventory:last-child {
    padding-bottom: 2px;
}

.full-table .filters th {
    padding: 10px 15px;
}

.full-table .form-control,
.full-table select.form-control:not([size]):not([multiple]) {
    height: 25px;
    font-size: 12px;
    border-color: #ebebeb;
    padding: 3px 4px;
    border-radius: 3px;
    width: auto;
    min-width: 65px;
}

.full-table a {
    text-decoration: none;
    font-weight: 500;
    color: #007ad9;
}

.full-table a b {
    font-weight: 500;
}

.full-table td .form-control,
.full-table .quantity {
    margin: 0;
    position: relative;
    height: 25px;
    min-height: 25px;
    min-width: 100px;
    width: 100%;
    top: 0;
}

.full-table .quantity {
    height: 32px;
    max-width: 110px;
    min-width: 105px;
    width: 100%;
    margin: 0 auto;
    background: #dfebf9;
    padding: 5px 0px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.remove-font {
    font-size: 12px;
    white-space: nowrap;
    width: 50px;
    display: block;
    margin-bottom: 10px;
}

a.toggleValue {
    float: right;
    color: #000;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 5px;
}

a.toggleValue:hover,
a.toggleValue:focus,
a.toggleValue:active {
    color: #000;
    text-decoration: none;
}

.load-listing {
    text-align: center;
    font-size: 30px;
    padding: 50px 0 0;
    margin-bottom: 30px;
    font-weight: 700;
    color: #ccc;
}

.round-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: 10px;
    position: relative;
    margin: -20px 0 0 0;
    overflow: hidden;
    background-color: #fafafa;
    display: block;
}

.round-img img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.pagination {
    justify-content: center;
    float: right;
}

a.page-link {
    font-size: 11px;
    background-color: #fff !important;
    color: #333;
    line-height: 24px;
}

.page-link,
.page-item:first-child .page-link,
.page-item:last-child .page-link {
    display: block;
    margin: 0 3px;
    height: 22px;
    padding: 0 3px;
    text-align: center;
    font-size: 17px;
    min-width: 22px;
    border-radius: 4px;
    line-height: 20px;
    color: #007bff;
    background-color: #fff;
    border: none;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2) !important;
}

.page-item.active .page-link {
    background-color: #daedfc !important;
    box-shadow: 0 0 2px #1f6ce8 !important;
    color: #1f6ce8 !important;
    font-weight: bold;
}

.page-item.disabled a,
.page-item.disabled .page-link {
    color: #999 !important;
    background-color: #ddd !important;
    box-shadow: none !important;
}

.page-item.disabled:hover {
    cursor: not-allowed !important;
}

.full-table.pricedata-table tr {
    background-color: #f5f5f5;
}

.full-table.pricedata-table th,
.full-table.pricedata-table td {
    padding: 20px 0 0 20px;
}

.full-table.pricedata-table tr:last-child td {
    padding-bottom: 20px;
}

.shown-entries {
    font-size: 12px;
    color: #9a9a9a;
    letter-spacing: 0.5px;
}

.different-table-design .data-table {
    border-radius: 0;
    box-shadow: none;
}

td.edit-box,
th.edit-box {
    padding-right: 0;
}

.edit-box a {
    visibility: hidden;
    opacity: 0;
}

tr:hover .edit-box a {
    visibility: visible;
    opacity: 1;
}

tr:hover {
    background-color: #f7f7f7;
}

.different-table-design .full-table th {
    background-color: #f9fafc;
    font-size: 14px;
    color: #000;
    vertical-align: middle;
    font-weight: 600;
}

.different-table-design .full-table td {
    border-bottom: 1px solid #f7f8f9;
}

.different-table-design .full-table th {
    border-bottom: 1px solid #ebebeb;
}

.different-table-design .vendor-selection .full-table th {
    border-right: 1px solid #ebebeb;
}

.different-table-design .vendor-selection .full-table td {
    border-right: 1px solid #f7f8f9;
}

.different-table-design .vendor-selection .full-table th:last-child,
.different-table-design .vendor-selection .full-table td:last-child {
    border-right: none;
}

.different-table-design .vendor-selection .full-table th.highlight,
.different-table-design .vendor-selection .full-table tfoot tr td.highlight {
    background-color: #f1f2f4;
}

.different-table-design .vendor-selection .full-table td.highlight {
    background-color: #f4fef4;
}

.tab-pane .data-table {
    border-radius: 0 8px 8px 8px;
}

.data-table .form-control.ng-select .ng-select-container .ng-value-container .ng-input {
    top: 0;
    padding-left: 0;
}

.data-table .form-control.ng-select .ng-select-container {
    height: 100%;
    padding: 0;
    min-height: 0;
}

.full-table tr:first-child th:first-child,
.full-table tr:first-child td:first-child {
    border-top-left-radius: 5px;
}

.full-table tr:first-child th:last-child,
.full-table tr:first-child td:last-child {
    border-top-right-radius: 5px;
    border-right: 1px solid #d5d5d5;
}

.full-table tr:last-child th:first-child,
.full-table tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
}

.full-table tr:last-child th:last-child,
.full-table tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
}

.full-table tr:first-child th,
.full-table tr:first-child td {
    border-top: none;
}

.full-table tr:first-child:hover,
.full-table tr:first-child {
    background-color: #f5fbff;
}

.full-table tr:first-child th {
    border: 1px solid #d5d5d5;
    border-right: 0;
}


/*
  NGX Date Table Style
*/

.ngx-datatable.material {
    box-shadow: none;
    border-radius: 6px;
    overflow: visible !important;

}

.ngx-datatable.material .datatable-header .datatable-header-cell {
    color: #000;
    font-weight: 600;
    font-size: 14px;
    padding: .5rem 1rem;
    border-right: 1px solid #ececec;
}

.ngx-datatable.material .datatable-footer {
    border-top: none;
    margin-top: 10px;
}

/* .ngx-datatable .datatable-body .datatable-body-row>div {
    border-bottom: 1px solid #f7f8f9;
} */

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    font-size: 13px;
    color: #333;
    font-weight: 400;
    line-height: 18px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
    min-height: 52px;
    height: 100% !important;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell .datatable-body-cell-label {
    display: block;
}

.ngx-datatable .datatable-body .datatable-body-row:last-child>div,
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-row-right .datatable-header-cell {
    border: 0;
}

.ngx-datatable.material .empty-row {
    height: auto;
    padding: 30px 10px;
    text-align: center;
    font-size: 25px;
    font-weight: 900;
    color: #dfdfdf;
    letter-spacing: 0.5px;
}

.datatable-body-cell-label a {
    text-decoration: none;
}

[class='sort-btn']:before {
    font-family: 'data-table' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sort-btn:before {
    content: '\66';
}

.sort-btn.sort-desc[class*='datatable-icon-down']::before {
    content: '\66';
}

.sort-btn.sort-asc[class*='datatable-icon-up']::before {
    content: '\65';
}

.ngx-datatable .datatable-body .datatable-body-row {
    border-bottom: 1px solid #ececec;
}

.datatable-row-wrapper:last-child {
    border-bottom: none;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background: #f7f7f7;
}

.ngx-datatable.material {
    background: none;
}

.ngx-datatable .datatable-body {
    background-color: #fff;
    border-radius: 0 0 5px 5px;
}

.ngx-datatable.material .datatable-header {
    background-color: #f5fbff;
    border-radius: 5px 5px 0 0;
    line-height: 40px;
    border: 1px solid #ececec;
}

.ngx-datatable .datatable-footer .datatable-pager .pager li a {
    background-color: #fff !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2) !important;
}

.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
    color: #999 !important;
    background-color: #ddd !important;
    box-shadow: none !important;
}

.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
    font-size: 20px;
    line-height: 29px;
    padding: 0;
    height: 20px;
    display: block;
}

.ngx-datatable.material .datatable-footer .datatable-pager li.active a,
.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
    background-color: #daedfc !important;
    box-shadow: 0 0 2px #1f6ce8 !important;
    color: #1f6ce8 !important;
    font-weight: bold;
}

.ngx-datatable.material .datatable-footer .page-count {
    padding-left: 0 !important;
}

.ngx-datatable.material .datatable-footer .datatable-pager {
    margin-right: 0 !important;
}

.ngx-datatable.material .datatable-footer .paging {
    width: 100%;
}

.ngx-datatable.material .datatable-footer .paging .datatable-pager {
    margin-top: -43px !important;
    float: right;
}

.ngx-datatable.material .datatable-header .resize-handle {
    border-right: none !important;
}

.ngx-datatable .datatable-body-cell {
    overflow-x: visible !important;
}

.datatable-body .form-control.ng-select {
    min-height: 0;
}

.datatable-body .form-control.ng-select .ng-select-container .ng-value-container .ng-input {
    padding: 2px 25px 2px 10px;
}

.datatable-body .ng-select.ng-select-single .ng-select-container .ng-value-container {
    padding: 3px 10px;
    font-size: 13px;
    font-weight: 500;
}

.datatable-body .ng-select.form-control {
    display: inline-block;
    width: auto;
    min-width: 100px;
    max-width: 100%;
}

.ngx-datatable .datatable-body .datatable-scroll {
    display: inline-block;
    max-width: 100%;
}

.ngx-datatable .ng-select .ng-arrow-wrapper .ng-arrow {
    border-width: 4px 4px 1.5px;
}

.ngx-datatable .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    border-color: #999 transparent transparent;
    top: 0;
}

.ngx-datatable.material .datatable-body .progress-linear .container {
    width: 100% !important;
    max-width: 100% !important;
}

.no-arrow .sort-btn::before {
    display: none;
}

.ngx-datatable .form-control,
table.table.full-table.req-table.price.update .form-control {
    height: 30px;
    max-width: 100%;
    border-radius: 2px;
}

table.table.full-table.req-table.price.update .form-control {
    height: 32px;
    margin: 0 auto;
    border-color: #d8d8d8;
}

.column-item {
    word-break: break-word;
}

.datatable-body-cell .btn {
    padding: 5px;
    font-size: 10px;
    line-height: normal;
    height: auto;
    min-height: 0;
    font-weight: 500;
    border-radius: 2px;
    min-width: 70px;
}

.datatable-body-cell .more-btn {
    min-height: 25px;
    height: 25px;
    border: none;
    padding-left: 5px;
    min-width: 26px;
    font-size: 13px;
}

.datatable-body-cell .more-btn.options {
    padding-left: 10px;
}

.datatable-body-cell .more-btn.btn-outline-primary:hover,
.datatable-body-cell .more-btn.btn-outline-primary:active,
.datatable-body-cell .more-btn.btn-outline-primary:focus {
    background: none;
    outline: none;
    border: none;
    box-shadow: none !important;
}

.datatable-body-cell .more-btn.btn-outline-primary:hover {
    color: #007bff;
}

.datatable-body-cell .btn:hover .more-dots::after,
.datatable-body-cell .btn:hover .more-dots::before,
.datatable-body-cell .btn:hover .more-dots,
.datatable-body-cell .btn:focus .more-dots::after,
.datatable-body-cell .btn:focus .more-dots::before,
.datatable-body-cell .btn:focus .more-dots {
    background-color: #444;
}

.datatable-body-cell .more-actions .dropdown-menu {
    width: 145px;
    min-width: 0;
}


/* .datatable-body-cell .more-dots::before,
.datatable-body-cell .more-dots::after,
.datatable-body-cell .more-dots {
    width: 3px;
    height: 3px;
} */

.column-item {
    cursor: pointer;
}

.applied-filterbox {
    margin-bottom: 5px;
    padding: 10px 15px;
    background-color: #fff;
    overflow: hidden;
    border-radius: 5px;
    white-space: nowrap;
    overflow-x: auto;
    scrollbar-width: thin;
}

.applied-filters {
    display: inline-block;
    margin: 2px 5px 2px 0;
    background-color: #e3dfdf;
    border-radius: 15px;
    padding: 4px;
    padding-left: 8px;
    font-weight: 600;
}

.applied-filters-child {
    background: #efeeee;
    border-radius: inherit;
    margin: 0 2px;
}

.remove-applied-filter {
    color: #cd2339 !important;
    font-size: 14px;
    font-weight: 100;
    margin-left: 4px;
}

.datatable-row-left .datatable-body-cell-label {
    margin: auto !important;
}

.datatable-row-left .datatable-body-cell {
    padding: 0 !important;
}

.tabledata-sendaction {
    position: fixed;
    bottom: 0;
    left: 81px;
    z-index: 98;
    background-color: #fff;
    right: 0;
    padding: 19px 50px;
    text-align: right;
    height: 74px;
}

.tabledata-sendaction.cart {
    display: flex;
    justify-content: space-between;
}

table tr.no-hover-effect:hover {
    background-color: #fff;
}

.ngx-datatable.material .datatable-body .progress-linear {
    position: fixed !important;
}

.pagination .page-item .page-link::before {
    font-family: 'data-table' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    color: rgba(0, 0, 0, 0.54);
    speak: none;
    line-height: 23px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pagination .page-item .page-link[aria-label*='First']::before {
    content: '\72';
}

.pagination .page-item .page-link[aria-label*='Previous']::before {
    content: '\6f';
    font-size: 18px;
    line-height: 24px;
}

.pagination .page-item .page-link[aria-label*='Last']::before {
    content: '\71';
}

.pagination .page-item .page-link[aria-label*='Next']::before {
    content: '\70';
    font-size: 18px;
    line-height: 24px;
}

.pagination .page-item .page-link[aria-label*='First'] span,
.pagination .page-item .page-link[aria-label*='Previous'] span,
.pagination .page-item .page-link[aria-label*='Next'] span,
.pagination .page-item .page-link[aria-label*='Last'] span {
    display: none;
}

.table.req-table {
    border-collapse: separate;
    border-spacing: 0;
    border: 0;
}

.table.req-table th {
    background-color: #f5fbff;
    min-height: 57px;
    border-top: 1px solid #d5d5d5 !important;
    border-left: 1px solid #d5d5d5;
    border-right: 0 !important;
    border-bottom: 1px solid #d5d5d5 !important;
}

.table.req-table th:first-child {
    min-width: 69px;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 5px !important;
}

.table.req-table th:nth-child(2) {
    min-width: 160px;
}

.table.req-table th:nth-child(3) {
    min-width: 117px;
}

.table.req-table th:nth-child(4) {
    min-width: 72px;
}

.table.req-table th:nth-child(5) {
   /*  min-width: 130px; */
}

.table.req-table th:nth-child(6),
.table.req-table th:nth-child(7),
.table.req-table th:nth-child(8) {
    min-width: 160px;
}

.table.req-table th:last-child {
    min-width: 80px;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 5px !important;
    border-right: 1px solid #d5d5d5 !important;
}

.table.req-table td a,
.table.req-table td a~b {
    font-size: 11px;
    line-height: 1.18;
    letter-spacing: 0.11px;
    color: #0075ff;
}

.table.req-table td a~b {
    color: #313131;
}

.table th a.add-items {
    font-size: 11px;
    letter-spacing: 0.2px;
    color: #046ae2;
}

.table.req-table tr td {
    border-bottom: 1px solid #d5d5d5;
    border-left: 1px solid #d5d5d5;
    color: #000;
}

.table.req-table tr td:last-child {
    border-right: 1px solid #d5d5d5 !important;
}

.table.req-table.last tr td:last-child {
    text-align: center;
}

.table.req-table tr:first-child td,
.table.req-table tr.add-p td {
    background: #f7f7f7;
    height: 33px;
    padding: 0 15px;
    border-radius: 0 !important;
}

.table.req-table tr td:first-child {
    text-align: center;
    font-weight: 500;
    color: #313131;
}

.table.req-table tr:last-child td {
    border-radius: 0;
}

.table.req-table tr.add-p td {
    border: 0 !important;
}

.table.req-table tr.add-p td:first-child {
    border-left: 1px solid #d5d5d5 !important;
}

.table.req-table tr.add-p td:last-child {
    border-right: 1px solid #d5d5d5 !important;
}

.ngx-datatable.material.new .datatable-header {
    border-bottom: 1px solid #d5d5d5;
}

.ngx-datatable.material.new .datatable-header .datatable-row-center {
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    background: #f5fbff;
}

.ngx-datatable.fixed-header.new .datatable-header .datatable-header-inner .datatable-header-cell {
    border-right: 1px solid #d5d5d5;
}

.ngx-datatable.fixed-header.new .datatable-header .datatable-header-inner .datatable-header-cell:last-child {
    border-top-right-radius: 5px;
}

.ngx-datatable.material.new .datatable-body .datatable-body-row {
    border-bottom: 0;
}

.ngx-datatable.material.new .datatable-body .datatable-body-row .datatable-body-cell {
    border-left: 1px solid #d5d5d5;
    border-spacing: 0;
    border-bottom: 1px solid #d5d5d5;
}

.ngx-datatable.material.new .datatable-body .datatable-body-row .datatable-body-cell:last-child {
    border-right: 1px solid #d5d5d5;
}

.ngx-datatable.material.new .datatable-body .datatable-row-wrapper:last-child .datatable-row-center .datatable-body-cell:first-child {
    border-bottom-left-radius: 5px;
}

.ngx-datatable.material.new .datatable-body .datatable-row-wrapper:last-child .datatable-row-center .datatable-body-cell:last-child {
    border-bottom-right-radius: 5px;
}

.ngx-datatable.material.new .datatable-header .datatable-row-left,
.ngx-datatable.material.new .datatable-body .datatable-row-left {
    border-right: 1px solid transparent !important;
}

.material.inventory-table.ngx-datatable .datatable-header .datatable-header-cell:last-child {
    text-align: center;
}

.material.inventory-table.ngx-datatable .datatable-body .datatable-row-wrapper .datatable-row-group .datatable-body-cell:last-child .datatable-body-cell-label {
    margin-left: auto !important;
    margin-right: auto;
}

.ngx-datatable.material.hideTableHeader .datatable-header {
    display: none;
}

.ngx-datatable.material.top .datatable-body .progress-linear {
    top: 40px !important;
}

.ngx-datatable.material.paging-table.rfq-table .datatable-body .datatable-row-center .datatable-body-cell:nth-child(3) .datatable-body-cell-label,
.ngx-datatable.material.paging-table.rfq-table .datatable-body .datatable-row-center .datatable-body-cell:nth-child(4) .datatable-body-cell-label,
.ngx-datatable.material.paging-table.inventory-table .datatable-body .datatable-row-center .datatable-body-cell:nth-child(3) .datatable-body-cell-label {
    display: block;
}

.ngx-datatable.material.paging-table.rfq-table .datatable-body .datatable-row-center .datatable-body-cell:nth-child(3) .datatable-body-cell-label small,
.ngx-datatable.material.paging-table.rfq-table .datatable-body .datatable-row-center .datatable-body-cell:nth-child(4) .datatable-body-cell-label small,
.ngx-datatable.material.paging-table.inventory-table .datatable-body .datatable-row-center .datatable-body-cell:nth-child(3) .datatable-body-cell-label small {
    font-weight: 600;
    display: block;
}

.datatable-body-cell .btn.add.text-white {
    font-size: 11px;
    padding: 5px 8px 6px;
}

.ngx-datatable.material .datatable-body .datatable-row-detail {
    background: #fff;
    padding: 0;
    position: relative;
}

.additional-details {
    box-shadow: 0 3px 5px 0px rgb(0, 0, 0, 35%);
    width: 100%;
}

.details-head {
    background: #e3e3e3;
    position: relative;
}

.details-head h3 {
    color: #666;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    padding: 3px 0;
    margin-bottom: 0;
}

.details-head a, .popup-close {
    position: absolute;
    right: 5px;
    top: 2px;
    background: #949494;
    width: 16px;
    height: 16px;
    line-height: 17px;
    text-align: center;
    border-radius: 50px;
    font-size: 9px;
    color: #e3e3e3;
}

.details-content {
    padding: 7px 0 12px;
    height: 175px;
    overflow-y: auto;
    justify-content: space-between;
    position: relative;
    scrollbar-width: thin;
}
.details-content.orders {
    height: 180px;
}

.details-content .view-all {
    color: #0074f2;
    position: absolute;
    top: 3px;
    right: 6px;
    font-size: 11px;
    z-index: 1;
}

.details-content::-webkit-scrollbar,
.tabular-detail.data-table .scroll-table::-webkit-scrollbar,
.country-ports-wrap::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.tabular-detail.data-table .scroll-table::-webkit-scrollbar,
.notification-list .sidebar-scroll::-webkit-scrollbar {
    width: 7px;
}

.tabular-detail.data-table.new-quotation .scroll-table::-webkit-scrollbar {
    height: 7px;
}

.details-content::-webkit-scrollbar-track,
.tabular-detail.data-table .scroll-table::-webkit-scrollbar-track,
.country-ports-wrap::-webkit-scrollbar-track,
.notification-list .sidebar-scroll::-webkit-scrollbar-track {
    background: #f0f0f0;
}

.tabular-detail.data-table .scroll-table::-webkit-scrollbar-track:hover,
.notification-list .sidebar-scroll::-webkit-scrollbar-track:hover {
    background: #e8e8e8;
}

.details-content::-webkit-scrollbar-thumb,
.tabular-detail.data-table .scroll-table::-webkit-scrollbar-thumb,
.country-ports-wrap::-webkit-scrollbar-thumb,
.notification-list .sidebar-scroll::-webkit-scrollbar-thumb {
    background: #cbcbcb;
    border-radius: 0;
}

.tabular-detail.data-table .scroll-table::-webkit-scrollbar-thumb {
    border-radius: 5px;
}

.details-content::-webkit-scrollbar-thumb:hover,
.tabular-detail.data-table .scroll-table::-webkit-scrollbar-thumb:hover,
.country-ports-wrap::-webkit-scrollbar-thumb:hover {
    background: #666;
}

.details-content>div {
    padding: 0 15px 0 24px;
    position: relative;
}

.details-content, .details-content>div .inner-details,
.details-content.orders.inventory .stock-details,
.details-content.orders.inventory .product-info {
    display: flex;
}

.details-content>div.order-details {
    max-width: 341px;
    width: 100%;
}

.details-content>div.delivery-details {
    max-width: 418px;
    width: 100%;
    padding-left: 25px;
}

.details-content>div.payment-details {
    max-width: 414px;
    width: 100%;
    padding-left: 22px;
}

.details-content.req-content>div.order-details {
    max-width: 327px;
}

.details-content.req-content>div.delivery-details {
    max-width: 363px;
    padding-left: 17px;
}

.details-content.req-content>div.payment-details {
    max-width: 471px;
    padding-left: 15px;
    padding-right: 20px;
}

.details-content.orders.inventory>div.order-details {
    max-width: 381px;
}

.details-content.orders.inventory>div.delivery-details {
    max-width: 470px;
}

.details-content.orders.inventory>div.payment-details {
    max-width: 345px;
}

.details-content.orders.inventory>div.change-status {
    max-width: 165px;
    padding-left: 15px;
    padding-top: 15px;
}

.details-content>div:not(:last-child):after {
    content: '';
    position: absolute;
    top: 10px;
    right: 0;
    background: #e3e3e3;
    width: 1px;
    height: 91%;
}

.details-content>div>*,
.details-content.orders.inventory .stock-details .inner-details,
.details-content.orders.inventory .product-info .inner-details {
    margin-bottom: 9px;
}

.details-content>div .inner-details:last-child,
.details-content>div .product-details {
    padding-bottom: 9px;
}

.details-content>div h3 {
    font-size: 16px;
    font-weight: 600;
}

.details-content.req-content>div.payment-details h3 {
    margin-bottom: 3px;
}

.details-content>div .inner-details p {
    font-size: 12px;
    font-weight: 500;
    min-width: 120px;
    margin-bottom: 0;
    line-height: 15px;
}

.details-content>div .inner-details p:last-child {
    width: auto;
    color: #808080;
    word-break: break-word;
    white-space: break-spaces;
}

.details-content>div.delivery-details .inner-details p {
    width: 164px;
}
.details-content.req-content>div.delivery-details .inner-details p {
    width: 160px;
}

.details-content>div .inner-details p.priority {
    color: #02a112;
}

.details-content>div .inner-details p.priority.urgent {
    color: #ce2339;
}

.details-content>div .product-details {
    display: flex;
}
.details-content.req-content>div .product-details {
    justify-content: space-between;
}

.details-content>div .product-details .product-count {
    min-width: 126px;
    width: 126px;
    min-height: 83px;
    background: #f5fbff;
    text-align: center;
    margin-right: 30px;
    border-radius: 4px;
}

.details-content.req-content>div .product-details .product-count {
    width: 128px;
    min-height: 75px;
    margin-right: 15px;
}

.details-content.req-content>div .product-details .product-count {
    margin-right: 0;
}

.details-content.req-content>div .product-details .product-count.vendor {
    background: none;
}

.details-content.req-content>div .product-details .product-count.vendor h3 {
    font-size: 12px;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
}
.details-content.req-content>div.payment-details h3.no-vendor {
    width: 100%;
    text-align: center;
    color: #979797;
    margin-top: 35px;
}

.details-content>div .product-details .product-count p {
    background: #deefff;
    padding-top: 2px;
    margin-bottom: 0;
    border-radius: 4px 4px 0 0;
    text-transform: uppercase;
    font-size: 12px;
}
.details-content.req-content>div .product-details .product-count p {
    padding: 1px 0;
    text-transform: none;
    font-size: 11px;
}
.details-content>div .product-details .product-count h2 {
    font-size: 45px;
    margin-bottom: 0;
}

.details-content.req-content>div .product-details .product-count h2 {
    font-size: 28px;
    margin: 10px 0 0;
    word-break: break-word;
    white-space: break-spaces;
    line-height: 32px;
}
.details-content.req-content>div .product-details .product-count h2.small {
    font-size: 25px;
}

.details-content>div .product-details .product-price {
    width: 210px;
    padding-top: 7px;
}
.details-content>div .product-details .product-price .inner-details {
    margin-bottom: 7px;
    justify-content: space-between;
}
.details-content>div .product-details .product-price .inner-details:nth-child(2) {
    margin-bottom: 5px;
}
.details-content>div .product-details .product-price .inner-details:nth-child(3) {
    padding-top: 3px;
    border-top: 1px solid #e3e3e3;
    margin-bottom: 10px;
}

.details-content>div .product-details .product-price .inner-details p {
    min-width: auto;
}
.details-content>div .product-details .product-price .inner-details p:last-child {
    color: #0074f2;
}
.details-content>div .product-details .product-price .inner-details:nth-child(3) p,
.details-content>div .product-details .product-price .inner-details:nth-child(3) p:last-child {
    font-weight: 700;
    color: #000;
}

.details-content>div .product-details .product-price a {
    font-size: 10px;
    font-weight: 500;
    float: right;
}

.details-content>div.change-status {
    padding: 24px 15px 0 5px;
    max-width: 155px;
    width: 100%;
}

.details-content>div.change-status a {
    display: block;
    background: #deefff;
    color: #000;
    height: 22px;
    margin-bottom: 11px;
    border-radius: 2px;
    width: 130px;
    font-size: 12px;
    line-height: 23px;
    font-weight: 500;
    padding-left: 13px;
    text-decoration: none;
}

.details-content>div.change-status a.reorder {
    line-height: 15px;
}

.details-content>div.change-status a img {
    position: relative;
    top: -1px;
}

.details-content>div.change-status a i {
    font-size: 13px;
    font-weight: 600;
}

.details-content>div.change-status a.status {
    background: #0074f2;
    color: #fff;
    text-align: center;
    padding-left: 0;
}

.details-content.req-content>div.change-status a.status {
    background: #d32600;
}

.ngx-datatable.material .datatable-body .datatable-row-left.expanded .datatable-body-cell {
    background: #ececec;
}
.details-content > div.change-status .more-actions {
    margin-bottom: 0;
}
.change-status .more-actions.open .dropdown-menu {
    margin-top: 22px !important;
    width: auto;
    padding: 0;
    min-width: 130px;
    border-radius: 0 0 5px 5px;
    background: #deefff;
    box-shadow: none;
}

.change-status .more-actions.open .dropdown-menu .dropdown-item {
    font-size: 12px;
    padding: 0px 13px;
    background: none;
    line-height: 24px;
    margin-bottom: 2px;
}
.change-status .more-actions.open .dropdown-menu .dropdown-item:hover {
    background: #add5f5;
}

.details-content>div.change-status .more-actions.open a.dropdown-item i {
    font-size: 12px;
    color: #309b00;
    margin-right: 2px;
}
.details-content>div.change-status .more-actions.open a.dropdown-item:last-child i {
    color: #c50d0d;
}

.details-content.req-content>div.delivery-details.summary {
    max-width: 431px;
}
.details-content.req-content>div.delivery-details.new {
    max-width: 395px;
}
.details-content.req-content>div.delivery-details.summary h3 {
    visibility: hidden;
    pointer-events: none;
}
.details-content.req-content>div.delivery-details.summary .inner-details p {
    width: 190px;
}

.ngx-datatable.listing .datatable-header .datatable-row-center,
.ngx-datatable.listing .datatable-body .datatable-body-row .datatable-row-center {
    width: 96.20% !important;
    min-width: 96.20% !important;
    justify-content: space-between;
}

.ngx-datatable.material .datatable-body .datatable-row-right {
    transform: translate3d(-20px, 0px, 0px) !important;
    position: fixed;
    right: -4px;
}

/* .ngx-datatable.material .datatable-body .datatable-row-center .datatable-body-cell:last-child {
    border-right: 0;
} */

.ngx-datatable.material .datatable-body .datatable-row-right .datatable-body-cell {
    /* border-left: 1px solid #ececec; */
    padding: 0 10px 0 7px;
    background: #fff;
}

.ngx-datatable.material.new.company-doc .datatable-header .datatable-row-left,
.ngx-datatable.material.new.company-doc .datatable-body .datatable-row-left,
.ngx-datatable.material.new.company-doc .datatable-body .datatable-row-center .datatable-body-cell {
    border-right: 0 !important;
}

.ngx-datatable.material.new.company-doc .datatable-header .datatable-row-center {
    border: 0;
}
.ngx-datatable.material.new.company-doc .datatable-body .datatable-body-row .datatable-body-cell {
    border-left: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
}
.ngx-datatable.fixed-header.new.company-doc .datatable-header .datatable-header-inner .datatable-header-cell,
.ngx-datatable.material.new.company-doc .datatable-body .datatable-body-row .datatable-body-cell:last-child {
    border-right: 1px solid #ececec !important;
}
.ngx-datatable.material.new.company-doc .datatable-header {
    border-bottom: 1px solid #ececec;
}

.ngx-datatable.material.kyc .datatable-body .datatable-row-left {
    border-right: 0 !important;
}

.ngx-datatable.kyc .datatable-row-center > .datatable-header-cell,
.ngx-datatable.kyc .datatable-row-center > .datatable-body-cell .datatable-body-cell-label {
    text-align: center;
    margin-inline: auto !important;
}

.ngx-datatable.kyc .datatable-row-center > .datatable-body-cell .datatable-body-cell-label .column-item {
    text-transform: capitalize;
}

.ngx-datatable.kyc .datatable-row-center > .datatable-body-cell .datatable-body-cell-label a {
    color: #2c9aff;
    font-size: 14px;
    font-weight: 500;
}

.details-content.orders.inventory {
    padding-top: 10px;
    height: 135px;
}

.details-content.orders.inventory .stock-details,
.details-content.orders.inventory .payment-info {
    justify-content: space-between;
    margin-bottom: 0;
}

.details-content.orders.inventory .stock-details > div:first-child {
    width: 40%;
}

.details-content.orders.inventory .stock-details > div:last-child {
    width: 57%;
}

.details-content.orders.inventory .stock-details > div .inner-details p:first-child {
    width: 100px;
    min-width: 100px;
}

.details-content.orders.inventory .stock-details > div .inner-details p:last-child {
    width: auto;
}

.details-content.orders.inventory>div {
    padding: 0 12px 0 15px;
}

.details-content.orders.inventory>div.delivery-details,
.details-content.orders.inventory>div.payment-details {
    padding-left: 18px;
}

.details-content.orders.inventory .product-info > .img-box {
    width: 91px;
    height: 87px;
    border: 1px solid #ebebeb;
    border-radius: 7px;
    padding: 5px;
    margin-right: 20px;
}

.details-content.orders.inventory .product-info > .img-box > img {
    width: 100%;
    height: 100%;
}

.details-content.orders.inventory .payment-info > .details-wrap {
    width: auto;
}

.details-content.orders.inventory>div .inner-details p {
    min-width: 130px;
}

.details-content.orders.inventory>div .inner-details p:last-child {
    min-width: auto;
}

.details-content.orders.inventory>div .product-info .inner-details p:last-child {
    color: #000;
    font-weight: 700;
}

table.table.full-table.req-table.price.update.new th:first-child,
table.table.full-table.req-table.price.update.new th:last-child {
  border-radius: 0 !important;
}

table.table.full-table.req-table.price.update.new th,
table.table.full-table.req-table.price.update.new td {
  padding: 0px 5px;
  text-align: center;
}

table.table.full-table.req-table.price.update.new th {
  height: 37px;
  min-height: 37px;
  font-size: 13px;
  padding-bottom: 8px;
  font-weight: 500;
}

table.table.full-table.req-table.price.update.new td {
  background: #fff;
  font-weight: 400;
  height: 37px;
  font-size: 14px;
}

.data-table.batch .scroll-table {
  overflow: auto;
  max-height: 485px;
  scrollbar-width: thin;
  padding-bottom: 4px;
}

table.table.full-table.req-table.price.update.new th,
table.table.full-table.req-table.price.update.new td,
table.table.full-table.req-table.price.update.new tr:first-child th,
table.table.full-table.req-table.price.update.new tr:last-child th,
table.table.full-table.req-table.price.update.new tr:first-child td,
table.table.full-table.req-table.price.update.new tr:last-child td {
  border-color: #ccc !important;
}

table.table.full-table.req-table.price.update.new td.border-right {
  border-right: 1px solid #ccc;
}

.table.full-table.req-table.price.update.new.productlist {
    min-width: auto;
}

.table.full-table.req-table.price.update.new.productlist th,
.table.full-table.req-table.price.update.new.productlist td {
    padding: 0px 8px;
    text-align: left;
    background: #fcfcfc;
}

.table.full-table.req-table.price.update.new.productlist th {
    height: 34px;
    min-height: 34px;
    font-size: 16px;
    padding-bottom: 6px;
    font-weight: 700;
    color: #656565;
}

.table.full-table.req-table.price.update.new.productlist td {
    min-height: 57px;
    padding: 9px 8px;
}

.table.full-table.req-table.price.update.new.productlist td,
.table.full-table.req-table.price.update.new.productlist td a.product-name {
    font-size: 14px;
    font-weight: 600;
}

.table.full-table.req-table.price.update.new.productlist td a.product-name {
    color: #0062cd;
}

.table.full-table.req-table.price.update.new.productlist td:nth-child(3) {
    color: #484848;
    font-weight: 400;
}

.table.full-table.req-table.price.update.new.productlist td:first-child,
.table.full-table.req-table.price.update.new.productlist td:last-child {
    text-align: center;
}

.tabledata-sendaction.new {
    padding: 11px 35px 10px 50px;
    height: 56px;
}
.full-table.charges-table tr td{
    height: 48px;
    padding: 10px 15px;
}

@media (max-width: 1400px) {
    .ngx-datatable.scroll-horz .datatable-body {
        overflow-x: auto !important;
    }
    .additional-details {
        position: fixed;
    }
}

/* @media (min-width: 1250px) and (max-width: 1400px) {
    .ngx-datatable.fixed-header .datatable-header .datatable-row-center .datatable-header-cell:first-child,
    .ngx-datatable.listing .datatable-body .datatable-row-center .datatable-body-cell:first-child {
        width: 180px !important;
    }
} */

@media (max-width: 1200px) {
    .table.full-table.req-table.price.update.new.productlist {
        min-width: 500px;
    }
}

@media (max-width: 767px) {
    .pagination {
        float: none;
        overflow-y: hidden;
        overflow-x: auto;
        padding-bottom: 10px;
        white-space: nowrap;
        justify-content: normal;
    }
    .table.full-table.req-table.price.update.new.productlist th {
        font-size: 15px;
    }
    .table.full-table.req-table.price.update.new.productlist td,
    .table.full-table.req-table.price.update.new.productlist td a.product-name {
        font-size: 14px;
    }
}
