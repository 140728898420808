
.form-control,
select.form-control:not([size]):not([multiple]) {
    width: 100%;
    resize: none;
    border-radius: 2px;
    font-size: 13px;
    border: 1px solid #e8e8e8;
    /* box-shadow: 0 0 4px inset rgba(204, 204, 204, 0.3); */
    height: 45px;
}
.product-req{
    display:flex;
    justify-content: flex-end;
}
.single-box {
    position: relative;
}
.form-control:focus {
    box-shadow: none;
    background-color: #fbfbfb;
    border-color: #ccc;
}
.search-subadmin{
    position: relative;
    display: inline-block;
    z-index: 9;
    vertical-align: top;
    width: 350px;
}
.inline-block {
    display: inline-block;
    vertical-align: top;
}

.inline-dropdown {
    display: inline-block;
    vertical-align: top;
}

textarea.form-control {
    height: 100px;
}

.errorbox {
    font-size: 13px;
    color: #ff0000;
}

.btn {
    padding: 8px 15px;
    line-height: normal;
    font-size: 13px;
    font-weight: 600;
    border-radius: 2px;
    min-height: 35px;
    letter-spacing: 1px;
}

.btn-primary {
    background-color: #0685ec;
}

.btn+.btn {
    margin-left: 10px;
}

.btn-outline-orange {
    border: 1px solid orange;
    color: orange;
}

.btn-outline-orange:hover,
.btn-outline-orange:active {
    background-color: orange;
    color: #fff;
}

.form-group label {
    font-size: 12px;
    margin-bottom: 5px;
    color: #000;
    /*text-transform: uppercase;*/
    letter-spacing: 0.5px;
    display: block;
}
.user-log{
    display: flex;
    justify-content: space-between;
    margin: 10px;
}
.error .form-control,
.error select.form-control:not([size]):not([multiple]),
 body .error .p-inputtext,body .error .ui-editor-container .ui-editor-toolbar, body .error .ui-editor-container .ui-editor-content {
    border-color: #ff0000;
    background-color: #ff00000f;
}

.mendatory {
    color: #f90505;
    font-weight: 600;
}

.quantity {
    position: relative;
    padding: 0 30px;
    width: 85px;
}

.quantity .count {
    width: 21px;
    height: 21px;
    background-color: transparent;
    z-index: 1;
    border: 0;
    cursor: pointer;
    text-align: center;
    user-select: none;
    outline: none;
    color: #006bdd;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    position: relative;
    top: -0.5px;
    border-radius: 50px;
    padding: 0;
    transition: all 0.1s ease;
}

.quantity .count:active {
    color: #fff;
    background: #5493bb;
}

.quantity .increase-count {
    top: 0.5px;
    font-size: 18px;
}
.vendor-popup {
    border: 1px solid #d8d8d8;
    padding: 10px;
    margin: 10px;
}

.vendor-popup.remove-heading h4 {
    display: none;
}


.tag {
    display: inline-block;
    padding: 2px 7px;
    font-size: 12px;
    font-weight: 400;
}

.round3 {
    border-radius: 2px;
}

.colorfade {
    opacity: 0.4;
}
.font500{
    font-weight: 500;
}
input[type='checkbox'] {
    vertical-align: middle;
}

.green-outline-link {
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid #3ac47d;
    color: #3ac47d;
    line-height: normal;
    background: none;
}

.green-outline-link[disabled] {
    cursor: default;
}

.green-filled-link {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid #3ac47d;
    color: #fff;
    background-color: #3ac47d;
    line-height: normal;
}

.green-outline-link[disabled] {
    opacity: 0.5;
}

.form-control.ng-select {
    padding: 0;
}

.form-control.ng-select .ng-select-container,
.rfq-subcatlist.ng-select.ng-select-disabled .ng-select-container {
    background: none;
    border-radius: 2px;
    border: none;
    min-height: 43px;
    padding: 0.375rem 0.75rem;
    -webkit-box-align: center;
    align-items: center;
    box-shadow: none;
    /* 0 0 4px inset rgba(204, 204, 204, 0.3); */
}

.rfq-subcatlist.ng-select .ng-select-container .ng-value-container {
    max-height: 75px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 7px !important;
    scrollbar-width: thin;
}


/* .rfq-subcatlist.export.ng-select .ng-select-container .ng-value-container {
    padding-left: 0px !important;
} */

.rfq-message {
    max-height: 90px;
    overflow: auto;
    scrollbar-width: thin;
}

.rfq-message::-webkit-scrollbar,
.rfq-subcatlist.ng-select .ng-select-container .ng-value-container::-webkit-scrollbar,
.applied-filterbox::-webkit-scrollbar {
    width: 5px;
    border-radius: 2px;
    height: 4px;
}

.rfq-message::-webkit-scrollbar-track,
.rfq-subcatlist.ng-select .ng-select-container .ng-value-container::-webkit-scrollbar-track,
.applied-filterbox::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 2px;
}

.rfq-message::-webkit-scrollbar-thumb,
.rfq-subcatlist.ng-select .ng-select-container .ng-value-container::-webkit-scrollbar-thumb,
.applied-filterbox::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 2px;
}

.rfq-message::-webkit-scrollbar-thumb:hover,
.rfq-subcatlist.ng-select .ng-select-container .ng-value-container::-webkit-scrollbar-thumb:hover,
.applied-filterbox::-webkit-scrollbar-thumb:hover {
    background: #777;
}

.expired-vendors.ng-select .ng-select-container,
.expired-ports.ng-select .ng-select-container {
    box-shadow: none;
    border-radius: 2px;
}

.expired-vendors.form-control,
select.form-control:not([size]):not([multiple]),
.expired-ports.form-control,
select.form-control:not([size]):not([multiple]) {
    border-radius: 2px;
    box-shadow: none;
}

.select-unit .form-control.ng-select .ng-select-container {
    box-shadow: none;
}

.form-control.ng-select .ng-select-container .ng-value-container {
    padding-left: 0;
}

.rfq-subcatlist.cat.ng-select .ng-select-container .ng-value-container {
    padding-left: 0 !important;
}

.form-control.ng-select .ng-select-container .ng-value-container .ng-input {
    top: 9px;
}

.select-unit .form-control,
.select-unit select.form-control:not([size]):not([multiple]) {
    float: left;
    width: 50%;
    border: none;
    max-width: 50%;
}

.select-unit .form-control {
    border-right: 1px solid #e8e8e8;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.select-unit .form-control.ng-select .ng-select-container:hover {
    box-shadow: none;
    border: none;
}

.select-unit {
    border-radius: 2px;
    border: 1px solid #e8e8e8;
}

.select-unit .form-control:last-child {
    border-right: none;
}

.export-btn {
    min-height: 0;
}

.more-dots {
    width: 4px;
    height: 4px;
    position: relative;
    background-color: #007bff;
    display: block;
    border-radius: 50%;
    top: 6px;
}

.options .more-dots {
    transform: rotate(90deg);
}

.more-dots::before,
.more-dots::after {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    background-color: #007bff;
    display: block;
    border-radius: 50%;
}

.options .more-dots,
.options .more-dots::before,
.options .more-dots::after {
    background-color: #3d3d3d;
}

.datatable-body-cell .btn.options:hover .more-dots,
.datatable-body-cell .btn.options:hover .more-dots::before,
.datatable-body-cell .btn.options:hover .more-dots::after{
    background-color: #007bff;
}

.more-dots::before {
    top: -7px;
}

.more-dots::after {
    bottom: -7px;
}

.more-btn:focus {
    background-color: #007bff;
}

.btn:hover .more-dots::after,
.btn:hover .more-dots::before,
.btn:hover .more-dots,
.btn:focus .more-dots::after,
.btn:focus .more-dots::before,
.btn:focus .more-dots {
    background-color: #fff;
}

.more-actions {
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.custom-checkbox {
    font-size: 12px;
    color: #000;
    font-weight: 600;
    margin: 0;
    position: relative;
    padding-left: 21px;
    letter-spacing: 0.2px;
    cursor: pointer;
}

.custom-checkbox.category {
    padding-left: 25px;
}

.custom-checkbox .shape {
    position: absolute;
    left: 0;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border: 1px solid #9f9f9f;
    border-radius: 1px;
    top: 50%;
    transform: translate(0, -50%);
}

.custom-checkbox .shape::after,
.custom-checkbox.category.requirement .shape::after {
    content: '\f00c';
    width: 5px;
    height: 5px;
    position: absolute;
    left: 1px;
    color: #007ad9;
    font: normal normal normal 10px/1 FontAwesome;
    top: 1.5px;
    display: none;
}

.custom-checkbox input[type='checkbox'],
.custom-checkbox input[type='radio'] {
    display: none;
}

.custom-checkbox input[type='checkbox']:checked~.shape,
.custom-checkbox input[type='radio']:checked~.shape,
.custom-checkbox.category.requirement input[type='checkbox']:checked~.shape,
.custom-checkbox.category.requirement input[type='radio']:checked~.shape {
    border-color: #007ad9;
    background-color: #d5edff;
}

.vendor-selection .custom-checkbox input[type='checkbox']:checked~.shape,
.vendor-selection .custom-checkbox input[type='radio']:checked~.shape {
    border-color: #51a251;
    background-color: #e4fce4;
}

.attachements{
    background:url('../assets/images/attachement.png');
}
.vendor-selection .custom-checkbox .shape::after {
    color: #51a251;
}

.vendor-selection .custom-checkbox-remove input[type='checkbox']:checked~.shape,
.custom-checkbox.custom-checkbox-remove.items input[type='checkbox']:checked~.shape {
    border-color: #c9313d;
    background-color: #c9313d;
    color: #fff;
}

.vendor-selection .custom-checkbox-remove .shape::after,
.custom-checkbox.custom-checkbox-remove.items .shape::after {
    color: #fff;
}

.custom-checkbox input[type='checkbox']:checked~.shape::after,
.custom-checkbox input[type='radio']:checked~.shape::after {
    display: block;
}
.custom-checkbox.custom-checkbox-remove.items input[type='checkbox']:checked~.shape::after {
    left: 0.5px;
}

.custom-checkbox.choose-vendor {
    border: 0;
    font-size: 9px;
    color: #999;
    text-transform: capitalize;
    border-radius: 2px;
    padding: 0px 0px 0px 25px;
    margin-bottom: 0;
}

.custom-checkbox.choose-vendor .shape {
    left: 13px;
    top: 9px;
}

.custom-checkbox.choose-vendor em {
    display: block;
    font-size: 12px;
    font-style: normal;
    display: -webkit-box;
    margin-top: 0;
    color: #000;
    -webkit-line-clamp: 2;
    /*-webkit-box-orient: vertical;*/
    overflow: hidden;
    /*text-overflow: ellipsis;*/
    text-transform: none;
    /*height: 25px;*/
    font-weight: 600;
    line-height: 1.28;
    margin-bottom: 2px;
}

.custom-checkbox.category input[type='checkbox']:checked~.shape,
.custom-checkbox.category input[type='radio']:checked~.shape {
    border-color: #646464;
    background-color: #fff;
}

.custom-checkbox.category .shape {
    width: 14px;
    height: 14px;
    border: 1px solid #646464;
    border-radius: 1px;
}

.custom-checkbox.category .shape::after {
    left: 0.5px;
    color: #646464;
    top: 1.5px;
}

.fa-star {
    color: #ccc;
}

.fa-star.checked {
    color: orange;
}

.subadmin{
    font-family: 'Work Sans', sans-serif;;
}
/* .vendor-selection .fa-star {
    font-size: 13.5px;
} */

.vendor-check input[type='checkbox'],
.vendor-check input[type='radio'] {
    display: none;
}

.vendor-check .custom-checkbox::before {
    content: '';
    position: absolute;
    left: 2px;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border: 1px solid #9f9f9f;
    border-radius: 2px;
    top: 2px;
}

.vendor-check .custom-checkbox::after {
    content: '\f00c';
    width: 5px;
    height: 5px;
    position: absolute;
    left: 4px;
    font: normal normal normal 10px/1 FontAwesome;
    top: 4.5px;
    display: none;
}

.vendor-check input[type='checkbox']:checked~.custom-checkbox::after,
.vendor-check input[type='radio']:checked~.custom-checkbox::after {
    display: block;
}

.vendor-check input[type='checkbox']:checked~.custom-checkbox,
.vendor-check input[type='checkbox']:checked~.custom-checkbox::before,
.vendor-check input[type='radio']:checked~.custom-checkbox::before {
    border-color: #51a251;
    background-color: #f4fef4;
}

.vendor-check input[type='radio']:checked~.custom-checkbox {
    background-color: transparent;
}

.vendor-check input[type='checkbox']:checked~.custom-checkbox em,
.vendor-check input[type='checkbox']:checked~.custom-checkbox.choose-vendor,
.vendor-check input[type='radio']:checked~.custom-checkbox em,
.vendor-check input[type='radio']:checked~.custom-checkbox.choose-vendor {
    color: #54a454;
}

.block-item {
    display: block;
    margin: 10px 0;
}

.btn-outline-default {
    border-color: #000;
}

.editable[readonly],
.editable:disabled,
body .p-inputtext,
body .ui-state-disabled,
body .ui-widget:disabled {
    width: 100%;
    border-width: 0;
    border-color: none;
    border-style: solid;
    padding: 0;
    opacity: 1;
    font-family: inherit;
    border-radius: 0;
    font-size: 16px;
    height: 30px;
    display: block;
    background-color: none;
}

body .selectParent .ui-widget:disabled,
.ship-company-dropdown.ng-select.ng-select-disabled,
.order-place-port.ng-select.ng-select-disabled {
    font-size: 13px !important;
    height: 45px !important;
    border: 1px solid #e8e8e8 !important;
    background: #f1f1f1;
    border-radius: 2px;
    padding: 10px 12px;
}

body .selectParent .ui-widget {
    width: 100%;
}

.ship-company-dropdown.ng-select.ng-select-disabled .ng-select-container,
.order-place-port.ng-select.ng-select-disabled .ng-select-container {
    font-size: 13px !important;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
    display: none;
}

body .p-inputtext {
    color: #888;
    font-weight: 400;
    opacity: 1;
    border-bottom-color: #fff !important;
}

body .p-inputtext,
body .p-inputtext:enabled,
body .p-inputtext:enabled:hover:not(.ui-state-error),
body .p-inputtext:enabled:focus:not(.ui-state-error) {
    color: #888;
    font-weight: 400;
    opacity: 1;
    width: 100%;
    resize: none;
    font-size: 13px;
    border: 1px solid #e8e8e8 !important;
    border-radius: 2px;
    padding: 10px 12px;
    box-shadow: none;
    /* 0 0 4px inset rgba(204, 204, 204, 0.3) */
    height: 45px;
}

body .have-middlebox.bg-white .p-float-label input.p-inputtext,
body .have-middlebox.bg-white .p-float-label input.p-inputtext:focus {
    box-shadow: none;
    border: none !important;
    border-bottom: 1px solid #e8e8e8 !important;
    border-radius: 0;
    background: transparent;
    padding: 0;
}

.p-float-label label {
    margin: 0;
    font-size: 13px;
    margin-left: -4px;
    color: #d4d4d4;
}

body .p-inputtext:disabled {
    border: none !important;
    box-shadow: none !important;
}

body .ui-calendar {
    position: relative;
    width: 100%;
    display: inline-block;
}

.editable {
    width: 100%;
    height: 30px;
    border-width: 0 0 2px;
    border-color: #fff;
    border-style: solid;
    font-size: 16px;
    font-weight: 400;
    color: #888;
}

.loading,
.data-table.products.scrollbar.no-data {
    text-align: center;
    font-weight: 700;
    font-size: 26px;
    padding: 20px;
    color: #ccc;
}

input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file']::-webkit-file-upload-button,
button {
    border-radius: 2px;
}

.btn.btn-lg {
    padding: 13px 40px;
    font-weight: 400;
}

.select-file {
    position: relative;
    width: 100%;
    padding: 12px 10px 10px 94px;
    font-size: 12px;
    border-radius: 2px;
    font-weight: 400;
    color: #828181;
    border: 1px solid #e8e8e8;
    box-shadow: none;
    height: 45px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.select-file input[type='file'] {
    border: none;
    padding: 0;
    box-shadow: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 3;
}

.select-file.drop-file {
    padding: 110px 30px 40px;
    text-align: center !important;
}

.select-file.drop-file .btn-span {
    background-color: #f1f1f1;
    display: inline-block;
    padding: 20px;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    font-size: 32px;
    position: absolute;
    transform: translate(-50%, 0);
    line-height: 40px;
    color: #ccc;
    left: 50%;
    top: 20px;
}

.select-file.drop-file:hover {
    background-color: #f1f1f1;
}

.select-file.drop-file:hover .btn-span {
    background-color: #e4e5e6;
}

.select-file .btn-span {
    background-color: #e7e9eb;
    display: inline-block;
    padding: 5px 20px;
    border-radius: 2px;
    position: absolute;
    font-size: 12px;
    color: #939495;
    left: 9px;
    top: 8px;
}

.custom-checkbox.visible-light {
    color: #ccc;
}

.custom-checkbox.visible-light .shape {
    opacity: 0.7;
}


/*form container start*/

.form-container {
    /* border: 1px solid #fafafa; */
    padding: 30px 29px 30px 30px;
    border-radius: 5px;
    position: relative;
    /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05); */
}

.form-container.pad-top-bottom {
    padding: 33px 29px 33px 30px;
}

.tab-pane .form-container {
    border-radius: 0 5px 5px 5px;
}

/*form container end*/


/* NgSelect */

.ng-select {
    font-size: 13px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 12px;
}

.form-control.ng-select {
    height: auto;
    min-height: 45px;
    box-shadow: none;
}

.select-unit .form-control.ng-select {
    background: none;
    box-shadow: none;
}

.shipname .form-control.ng-select .ng-select-container {
    /* min-height: 40px; */
    box-shadow: none;
}

.shipname .form-control.ng-select {
    height: 40px;
   /*  min-height: 40px; */
    box-shadow: none;
    border-radius: 2px;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #d4d4d4;
}

.inline-dropdown .form-control.ng-select {
    min-height: 0;
    height: auto;
    background: none;
    font-size: 20px;
    border-radius: 0;
    box-shadow: none;
    margin: 0 0 0 7px;
    border-width: 0 0 2px;
    border-bottom-color: #999;
}

.inline-dropdown .ng-select .ng-arrow-wrapper {
    width: auto;
    padding: 0;
}

.inline-dropdown .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    box-shadow: none;
}

.inline-dropdown .form-control.ng-select .ng-select-container {
    background: none;
    border-radius: 0;
    border: none;
    margin-top: -4px;
    min-height: 0;
    padding: 0;
    align-items: center;
}

.inline-dropdown .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-size: 15px;
}

.inline-dropdown .form-control.ng-select .ng-select-container .ng-value-container .ng-input {
    top: 0;
    padding: 0 14px 0 0;
}

.inline-dropdown .ng-dropdown-panel {
    min-width: 200px;
}

.form-control.ng-select.ng-select-disabled {
    min-height: 0;
    border: none;
    box-shadow: none;
}

.form-control.ng-select.ng-select-disabled .ng-select-container {
    border-radius: 0;
    border: none;
    min-height: 0;
    color: #888;
    font-size: 15px;
    height: auto;
    padding: 1px 12px 0 0;
    box-shadow: none;
}

.port-country-dropdown.ng-select,
.port-country-dropdown.ng-select .ng-select-container {
    min-height: 26px;
    border-radius: 2px;
    box-shadow: none;
}

.port-country-dropdown.ng-select .ng-select-container {
    height: 26px;
    padding-top: 8px;
}

.port-country-dropdown.ng-select .ng-select-container .ng-value-container .ng-input {
    top: unset;
}

.port-country-dropdown.ng-select .ng-arrow-wrapper {
    padding-right: 0;
    right: -5px;
}

.rfq-subcatlist.ng-select.ng-select-disabled {
    border: 1px solid #e8e8e8;
}

.rfq-subcatlist.ng-select.ng-select-disabled .ng-select-container {
    height: 43px;
}

.rfq-subcatlist.pink-bg .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:first-child {
    background: #fff0f0;
    font-weight: 500;
}

.rfq-subcatlist.pink-bg .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:first-child:hover {
    background: #f5faff;
}

.budget-months.form-control.ng-select.ng-select-disabled .ng-select-container {
    background: #e9ecef;
    border-radius: 4px;
    height: 43px;
    padding-left: 10px;
    font-size: 13px
}

.ng-select .ng-select-container:hover {
    box-shadow: none !important;
}

.inline-dropdown .form-control.ng-select,
.inline-dropdown .form-control.ng-select .ng-select-container {
    box-shadow: none;
}

.cta-btn,
.btn.cta-btn,
.btn.btn-lg {
    font-size: 16px;
    padding: 15px 30px;
    font-weight: 600;
    line-height: normal;
}

.btn.cta-btn .plus-sign {
    font-size: 22px;
    display: inline-block;
    vertical-align: middle;
}

.btn.cta-btn .plus-sign.req-btn {
    font-size: 18px;
    position: relative;
    top: -1px;
}

.btn .plus-sign {
    font-size: 17px;
    display: inline-block;
    vertical-align: middle;
}

.cal-footer {
    text-align: right;
    position: absolute;
    padding-right: 17px;
    display: block;
    right: 0;
    bottom: 19px;
}

body .ui-datepicker .ui-datepicker-buttonbar {
    padding-right: 85px;
}

.inline-dropdown+.inline-dropdown {
    margin-left: 20px;
}

label {
    font-size: 12px;
    margin-bottom: 5px;
    color: #000;
    font-weight: 400;
}

.status-box .thin-font {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
}

.status-box {
    vertical-align: top;
    display: inline-block;
    position: absolute;
    width: 100%;
    top: 0;
}

.more-actions .dropdown-menu {
    width: 210px;
}

.more-actions .dropdown-menu .dropdown-item {
    white-space: normal;
    font-size: 14px;
    padding: 7px 20px;
}

.modal-content {
    border: none;
}

.searchbox .ng-autocomplete .p-autocomplete {
    width: 100%;
}


body .searchbox .ng-autocomplete .p-autocomplete .p-inputtext {
    border: 1px solid #d5d5d5 !important;
    box-shadow: none;
    padding: 4px 11px;
    color: #000;
    text-overflow: ellipsis;
    padding-right: 52px;
    font-weight: 400;
    height: 30px;
    text-transform: none !important;
    border-radius: 2px;
}

body .searchbox .ng-autocomplete .long-search .p-autocomplete .p-inputtext {
    height: 40px;
}

body .modal-dialog .searchbox .ng-autocomplete .p-autocomplete .p-inputtext {
    height: 45px;
}

.search-categories .searchbox .ng-trigger-overlayAnimation {
    position: absolute;
    top: 46px !important;
    right: 0 !important;
    width: 100% !important;
    left: auto !important;
}

.ui-helper-clearfix {
    max-width: 100%;
}

.search-categories .searchbox .ui-autocomplete-loader {
    right: 50px;
}

.btn:disabled,
.buttn:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.btn.btn-outline-default:disabled {
    border-color: #808080;
    color: #444;
    background-color: #fff;
}

.btn.btn-secondary.default
    {
        margin-right: 12px;
        /* height: 33px;
        font-size: 12px;
        padding: 6px 10px;
        min-height: 33px; */
        background-color: #fff;
        color: #000;
    }

.ui-calendar .ui-datepicker {
    max-width: initial;
}

.custom-checkbox.block-type {
    border: 1px solid #e8e8e8;
    padding: 12px 10px 12px 40px;
    margin: 0;
    display: block;
    font-weight: 400;
    border-radius: 2px;
    box-shadow: none;
    height: 45px;
    text-transform: none;
}

.block-type .shape {
    top: 13px;
    left: 13px;
    transform: none;
}

.full-click {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.filters {
    position: relative;
    /*padding-top: 8px;*/
    z-index: 1;
}

.click-shadow.show {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9999;
}

.filter-options {
    background-color: #fff;
    padding: 30px;
    position: absolute;
    max-width: 90vw;
    visibility: hidden;
    opacity: 0;
    right: 0;
    top: 45px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.14);
    z-index: 9999;
    border-radius: 5px;
    width: 983px;
    transition: all 0.2s ease 0s;
}

.filter-options.show {
    visibility: visible;
    opacity: 1;
    top: 55px;
}

.filter-options.top.show {
    top: 35px;
}

.edit-data {
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 10;
}

.padding0 {
    padding: 0;
}

.relative {
    position: relative;
}
.back-btn{
    font-size: 10px;
    color: #000;
    font-weight: 400;
    letter-spacing: 0;
}
.back-btn:hover{
    color: #000;
}

.back-btn.detail {
    font-size: 10px;
    color: #000;
    font-weight: 400;
}

.back-btn.detail.size {
    font-size: 11px;
}

.back-btn .fa-arrow-left {
    margin-top: -1px;
    font-size: 10px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.back-btn .fa-angle-left {
    margin-right: 6px;
}

.fa-trash {
    color: #333;
    cursor: pointer;
}

.btn.focus,
.btn:focus {
    box-shadow: none !important;
}

.add-price {
    position: relative;
}

.add-price .currency-code {
    position: absolute;
    top: 6px;
    left: 6px;
    font-weight: 500;
    color: #969292;
}

.add-price.normal-field .currency-code {
    position: absolute;
    top: 13px;
    left: 11px;
    font-size: 13px;
    font-weight: 500;
    color: #969292;
}

.add-price .currency-code.tax-field {
    left: 82%;
}

.add-price.product .currency-code.tax-field {
    left: 92%;
    top: 12px;
}

.add-price.normal-field .form-control {
    padding-left: 40px;
}

.add-price.normal-field.weight .form-control {
    padding-left: 30px;
}

.add-price .form-control,
table.table.full-table.req-table.price.update td .add-price .form-control {
    padding-left: 35px;
}

.add-price .form-control.tax-field,
table.table.full-table.req-table.price.update td .add-price .form-control.tax-field {
    padding-left: 0.75em;
    padding-right: 30px;
}

.product-modal {
    max-width: 400px;
}


.padding40 {
    padding: 40px;
}

.padding20 {
    padding: 20px;
}

.padding30 {
    padding: 30px;
}

.bmargin40 {
    margin-bottom: 40px;
}

.tpadding0 {
    padding-top: 0;
}

.round20 {
    border-radius: 20px;
}

.tag a.cross {
    font-size: 18px;
    line-height: 8px;
    height: 10px;
    display: inline-block;
    vertical-align: middle;
}

.loadingText {
    color: #ccc;
}

.cal-footer.single-btn {
    position: initial;
    margin-top: 20px;
}

.height35 .form-control.ng-select .ng-select-container,
.height35 .form-control.ng-select {
    min-height: 36px;
    height: 36px;
}

.width190 {
    width: 190px;
    max-width: 190px;
}

.height35 .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 6px;
}

.waiting {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
}

.email {
    text-transform: none;
}

.search-categories {
    position: relative;
    display: inline-block;
    /* z-index: 9; */
    vertical-align: top;
    width: 210px;
}

.search-categories .searchbox {
    max-width: 100%;
    width: 100%;
    position: relative;
}

.search-categories .form-control {
    border: 1px solid #e8e8e8;
    height: 36px;
    padding-right: 28px;
    text-overflow: ellipsis;
}

.search-categories .find-btn {
    position: absolute;
    right: 0;
    bottom: 1.5px;
    background: none;
    border: none;
    font-weight: 300;
    font-size: 18px;
    color: #ccc;
    width: 38px;
    height: 28px;
    outline: none;
    box-shadow: none;
}

.search-categories .find-btn span.fa-search {
    font-size: 16px;
}

body .ui-editor-container .ui-editor-content {
    border: 1px solid #e8e8e8;
    box-shadow: none;
    /* 0 0 4px inset rgba(204, 204, 204, 0.3) */
    border-radius: 0 0 2px 2px;
}

body .ui-editor-container .ui-editor-content .ql-editor {
    background: none;
}

body .ui-editor-container .ui-editor-toolbar {
    border: 1px solid #e8e8e8;
    background-color: #fff;
    box-shadow: none;
    border-radius: 2px 2px 0 0;
}

.error .select-file {
    border-color: #ff0000;
    background: #ff00000f;
}

.small-dropdown .form-control.ng-select {
    height: 35px;
    min-height: 35px;
    box-shadow: none;
}

.small-dropdown .form-control.ng-select .ng-select-container {
    min-height: 34px;
    height: 34px;
    padding: 5px 4px 5px 15px;
    min-width: 150px;
}

.small-dropdown .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 7px;
    padding: 0 30px 0 15px;
}

.ql-editor.ql-blank::before {
    font-style: normal;
    opacity: 0.8;
    color: #495057;
    letter-spacing: 0.3px;
    font-weight: 400;
    font-size: 13px;
    font-family: 'Work Sans', sans-serif;
}

.lmargin5 {
    margin-left: 5px;
}

.product-variant-box {
    padding: 10px 0 2px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
}

.product-variant-box .vendor-check.variant-product {
    margin-right: 10px;
}

.vendor-check.variant-product .custom-checkbox.choose-vendor {
    padding: 5px 10px 4px 25px;
    margin-bottom: 8px;
    background: #ebf5ff;
}

.vendor-check.variant-product .custom-checkbox::before {
    left: 5.5px;
    top: 7px;
}

.vendor-check.variant-product .custom-checkbox::after {
    left: 7.5px;
    top: 9px;
}

.vendor-check.variant-product .custom-checkbox.choose-vendor em {
    margin-top: 2px;
    color: #333;
}

.vendor-check.variant-product input[type='radio']:checked~.custom-checkbox,
.vendor-check.variant-product input[type='radio']:checked~.custom-checkbox::before {
    border-color: #6fa2d8;
    background-color: #ebf5ff;
}

.vendor-check.variant-product input[type='radio']:checked~.custom-checkbox em,
.vendor-check.variant-product input[type='radio']:checked~.custom-checkbox.choose-vendor {
    color: #007ad9;
}

.custom-checkbox input[type='checkbox']:checked~.shape.disabled,
.custom-checkbox input[type='radio']:checked~.shape.disabled {
    border-color: #aab1b5;
    background-color: #e9ecef;
}

.custom-checkbox input[type='checkbox']:checked~.shape.disabled,
.custom-checkbox input[type='radio']:checked~.shape.disabled {
    border-color: #aab1b5;
    background-color: #e9ecef;
}

.custom-checkbox .shape.disabled::after {
    color: #a3a3a3;
}

.modal-dialog.gray.modal-sm.small {
    max-width: 350px;
}

.budget-type .custom-checkbox .shape {
    border-radius: 20px;
    width: 14.5px;
}

.budget-type .custom-checkbox:after {
    top: 2px;
}

.btn.cta-btn.create-req {
    width: 172px;
    height: 36px;
    padding: 10px 3px 8px;
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(198, 198, 198, 0.08);
    background-color: #2585ec;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.07;
    letter-spacing: 0.14px;
}

.btn.cta-btn.create-req:hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

.bmargin46 {
    margin-bottom: 46px;
}

.card.new-card {
    box-shadow: 0 2px 10px 0 rgba(198, 198, 198, 0.08);
    border: solid 0.5px #d5d5d5;
    height: 100%;
}

.card.new-card .card-header {
    padding: 13px 8px 15px 14px;
    height: 48px;
    text-transform: uppercase;
    position: relative;
    background: #f5fbff;
}

.card.new-card.expenditure .card-header,
.card.new-card.ship .card-header {
    height: 57px;
    line-height: 1.75;
    padding-left: 24px;
}

.card.new-card.ship .card-header {
    padding-left: 14px;
}

.card.new-card .card-header .dropdown.dropleft,
.comparison-inner .card-header .dropdown.dropleft {
    position: absolute;
    right: 6px;
}

.comparison-inner .card-header .dropdown.dropleft {
    top: 10px;
    right: 8px;
}

.card.new-card.expenditure .card-header .dropdown.dropleft {
    top: 17px;
}

.card.new-card .card-header .dropdown.dropleft .more-btn,
.comparison-inner .card-header .dropdown.dropleft .more-btn {
    padding: 3px 6px 16px !important;
    display: inline-block;
}

.card.new-card .card-body.new-body {
    padding-left: 50px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-top: 0px;
}

.card.new-card.orders .card-body.new-body {
    padding-left: 40px;
}

.card.new-card.vendor .card-body.new-body {
    padding-left: 35px;
    padding-bottom: 30px;
    padding-top: 0px;
}

.card.new-card .card-body .report-chart canvas {
    height: auto !important;
    max-width: 380px !important;
    width: 100% !important;
}

.card.new-card.ship .card-body .report-chart canvas {
    max-width: unset !important;
}

.card.new-card.expenditure .card-body .report-chart canvas {
    max-width: 524px !important;
}

.card.new-card.vendor .card-body .report-chart canvas {
    max-width: 430px !important;
}

.card.new-card.expenditure .card-body {
    padding-left: 25px;
    padding-bottom: 20px;
}

.date-pick .p-inputtext,
.date-pick .p-inputtext:enabled,
.date-pick .p-inputtext:enabled:hover:not(.ui-state-error),
.date-pick .p-inputtext:enabled:focus:not(.ui-state-error) {
    color: #6c6c6c;
    font-size: 12px;
    border: 1px solid #c9c9c9 !important;
    border-radius: 2px;
    box-shadow: none;
    height: 31px;
    line-height: 1.25;
    letter-spacing: 0.12px;
}

body .error .p-inputtext,
body .error .p-inputtext:enabled:hover:not(.ui-state-error) {
    border-color: #ff0000 !important;
}

.new-dropdown.ng-select {
    font-size: 12px;
    border: 1px solid #c9c9c9;
    min-height: 30px;
    border-radius: 2px;
}

.new-dropdown.ng-select .ng-select-container {
    height: 29px;
    border-radius: 2px;
    min-height: 30px;
    padding: 0rem 0.60rem;
    box-shadow: none;
}

.new-dropdown.ng-select.ng-select-multiple .ng-select-container {
    height: auto;
    min-height: 30px;
}

.new-dropdown.ng-select .ng-arrow-wrapper,
.comparison-inner .card-body .ng-select .ng-arrow-wrapper {
    padding-right: 0;
    padding-top: 2px;
}

.new-dropdown.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input,
.new-dropdown.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 5px;
}

.dropdown-box .new-dropdown.ng-select .ng-select-container .ng-value-container .ng-input,
.dropdown-box .new-dropdown.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    font-size: 15px;
}

.dropdown-box .new-dropdown.ng-select .ng-select-container {
    top: 2px;
}

.new-dropdown.ng-select .ng-clear-wrapper {
    top: 1px;
    right: -8px;
}

.seperter-div {
    width: 100%;
    margin: 0;
    padding: 0;
}

.table.category-table {
    border-collapse: separate;
    border-spacing: 0;
    border: 0 !important;
}

.table.category-table thead th {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.15px;
    background: #f5fbff;
}

.table.category-table thead th:first-child {
    border-radius: 5px 0 0 0;
}

.table.category-table thead th:last-child {
    border-radius: 0 5px 0 0;
    border-right: 1px solid #dee2e6;
}

.table.category-table tbody tr:last-child td:first-child {
    border-radius: 0 0 0 5px;
}

.table.category-table tbody tr:last-child td:last-child {
    border-radius: 0 0 5px 0;
}

.category-table.table-bordered td,
.category-table.table-bordered th {
    border-bottom-width: 0px;
    border-right: 0;
    border-bottom: 0;
}

.table.category-table tbody tr td:last-child {
    border-right: 1px solid #dee2e6;
    text-align: right;
}

.table.category-table tbody tr:last-child td {
    border-bottom: 1px solid #dee2e6;
}

.upload-wrapper {
    display: flex;
}

.upload-product-box {
    width: 268px;
    height: 104px;
    padding: 10px 35px 12px 12px;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(198, 198, 198, 0.08);
    border: solid 0.5px #008f01; /*#1395ee*/
    background-color: #f1ffef;
   /*  margin-bottom: 20px;*/
    margin-right: 20px;
    position: relative;
}

.upload-product-box h5 {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.25;
    margin: 0 0 4px;
}

.upload-product-box p {
    font-size: 10px;
    line-height: 1.3;
    color: #414141;
    margin-bottom: 14px;
}

.upload-product-box p a {
    color: #0071f8;
    text-decoration: underline;
}

.upload-product-box button.btn {
    width: 82px;
    min-height: 22px;
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgb(198 198 198 / 8%);
    background-color: #ffffff;
    font-size: 9px;
    font-weight: 600;
    line-height: 0.4;
    letter-spacing: 0.09px;
    padding: 2px 5px 0px;
    border: 1px solid #008f01; /*#1395ee*/
}

.upload-product-box img,
.upload-product-box.request-place img {
    width: 36px;
    height: 35.3px;
    position: absolute;
    right: 18px;
    bottom: 11px;
}

.upload-product-box.request-place {
    width: 295px;
    height: 104px;
    padding: 12px 30px 12px 18px;
    border-radius: 5px;
    border: solid 1px #f4a23f;
    background-color: #fff2d9;
}

.upload-product-box.request-place button.btn {
    border: solid 1px #f4a23f;
}

.upload-product-box.request-place img {
    width: 51px;
    height: 41px;
    right: 14px;
    bottom: 10px;
}

.btn.empty-cart {
    width: 114px;
    min-height: 25px;
    height: 25px;
    padding: 2px 0;
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(198, 198, 198, 0.08);
    border: solid 1px;
    border-color: #a3a3a3;
    background-color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.12px;
    color: #898989;
}
.btn.filter-multiple {
    min-height: 25px;
    height: 25px;
    padding: 5px 10px;;
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(198, 198, 198, 0.08);
    border: solid 1px;
    border-color: #a3a3a3;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.12px;
    color: #fff;
}

.fa-close:before{
    content: "\f00d";
    background-color: #fff;
    border-radius: 50%;
    color: black;
    margin-right: 12px;
    padding: 1px;
    font-size: 10px;
    font-weight: 100;
}
.btn.filter-multiple:hover{
    color: #fff;
    background-color: #007ad9;
}
.btn.empty-cart:hover,
.btn.empty-cart:focus,
.btn.empty-cart:active,
.btn.empty-cart:hover i,
.btn.empty-cart:focus i,
.btn.empty-cart.filter:hover,
.btn.empty-cart.filter:focus,
.btn.empty-cart.filter:active,
.btn.empty-cart.filter:hover i,
.btn.empty-cart.filter:focus i {
    background-color: #007ad9;
    color: #fff;
}

.btn.empty-cart:hover i,
.btn.empty-cart:focus i,
.btn.empty-cart.red:hover i,
.btn.empty-cart.red:focus i {
    background: none;
}

.btn.empty-cart:hover,
.btn.empty-cart:focus,
.btn.empty-cart:active {
    border-color: #007ad9;
}

.btn.empty-cart i {
    color: #898989;
}

.btn.empty-cart.red i {
    color: #ce3039;
}

.btn.empty-cart.red:hover,
.btn.empty-cart.red:focus,
.btn.empty-cart.red:active,
.btn.empty-cart.red:hover i,
.btn.empty-cart.red:focus i {
    background-color: transparent;
}

.btn.empty-cart.red:hover,
.btn.empty-cart.red:focus,
.btn.empty-cart.red:active {
    border-color: #ce3039;
    color: #898989;
}

.remove-item-icon {
    max-width: 14px;
    height: 18px;
    display: inline-block;
    text-align: center;
}

.remove-item-icon span {
    color: #9f9f9f;
}

.full-table.new-table {
    border-radius: 5px;
}

.full-table.new-table tr:first-child th:first-child,
.full-table.new-table tr:first-child td:first-child {
    border-top-left-radius: 5px;
}

.full-table.new-table tr:first-child th:last-child,
.full-table.new-table tr:first-child td:last-child {
    border-top-right-radius: 5px;
}

.dropdown-menu.show {
    text-transform: none;
}

.card.new-card.vendor {
    width: 95%;
}

.card.new-card.rfq-count {
    max-width: 260px;
    width: 100%;
    height: auto;
    padding: 0;
    margin-left: 36px;
    box-sizing: border-box;
    text-align: center;
}

.card.new-card.rfq-count.rfq-expire {
    margin-left: 0;
}

.card.new-card.rfq-count .card-header {
    height: auto;
    padding: 19px 14px 17px 13px;
}

.card.new-card.rfq-count.rfq-expire .card-header {
    background-color: #ffeff1;
}

.card.new-card.rfq-count .card-header .head {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: 0.14px;
}

.card.new-card.rfq-count .card-header .subhead {
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.12px;
    text-transform: none;
    margin: 0;
}

.card.new-card.rfq-count .new-body {
    padding: 56px 20px 20px;
}

.card.new-card.rfq-count .new-body .count {
    font-size: 60px;
    font-weight: bold;
    line-height: 0.8;
    letter-spacing: 0.6px;
    color: #1665c1;
    margin-bottom: 32px;
}

.card.new-card.rfq-count.rfq-expire .new-body .count {
    color: #ce3039;
}

.card.new-card.rfq-count .new-body .update-all {
    width: 155px;
    height: 30px;
    border-radius: 5px;
    background-color: #f7f7f7;
    border: 1px solid transparent;
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: 0.12px;
    color: #696969;
    padding: 0;
    outline: none;
}

.card.new-card.rfq-count .new-body .update-all:hover,
.card.new-card.rfq-count .new-body .update-all:focus,
.card.new-card.rfq-count .new-body .update-all:active {
    background-color: #e7e7e7;
}

.buttn {
    width: 69px;
    height: 30px;
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(198, 198, 198, 0.08);
    background-color: #2585ec;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.07;
    letter-spacing: 0.14px;
    text-align: center;
    color: #fff;
    border: 1px solid;
    border-color: #2585ec;
    outline: none;
}

.buttn:hover,
.buttn:active,
.buttn:focus,
.buttn.empty-cart.order:hover,
.buttn.empty-cart.order:focus {
    background-color: #0069d9;
    border-color: #0069d9;
    outline: none;
}

.buttn-outline {
    width: 73px;
    height: 30px;
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(198, 198, 198, 0.08);
    border: solid 0.5px;
    border-color: #2585ec;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.45;
    letter-spacing: 0.13px;
    text-align: center;
    color: #0075ff;
    background-color: transparent;
    outline: none;
    margin-right: 15px;
}

.buttn-outline:hover,
.buttn-outline:active,
.buttn-outline:focus {
    background-color: #2585ec;
    color: #fff;
    outline: none;
}

.buttn:disabled {
    border-color: #2585ec;
    background-color: #2585ec;
}

.btn.empty-cart.delete-all {
    background-color: #ce3039;
    color: #fff;
    border-color: #ce3039;
}

.btn.empty-cart.delete-all.items {
    width: 163px;
}

.btn.empty-cart.cancel {
    width: 61px;
}

.btn.empty-cart.cancel:hover,
.btn.empty-cart.cancel:active,
.btn.empty-cart.cancel:focus {
    background-color: #fff;
    color: #ce3039;
    border-color: #ce3039;
}

.buttn-outline.edit {
    width: 95px;
    margin-right: 0;
}

.buttn.place {
    width: 134px;
    height: 36px;
    font-size: 14px;
}

.buttn.place.cart {
    width: 172px;
}

.buttn.place.import {
    width: 105px;
}

.btn.update {
    border-radius: 2px;
}

.discount-box .buttn.discount {
    font-size: 12px;
    height: 32px;
}

.buttn.discount,
.buttn.discount.req {
    width: auto;
    height: 35px;
    border-radius: 0 2px 2px 0;
    border: 1px solid transparent;
}

.buttn.empty-cart,
.buttn-outline.select,
.btn.empty-cart.delete-all.item,
.btn.empty-cart.cancel.item {
    width: 109px;
}

.btn.empty-cart.delete-all.item,
.btn.empty-cart.cancel.item.top {
    height: 30px;
}

.btn.empty-cart.cancel.item {
    border-color: #ce3039;
    color: #ce3039;
}

.btn.empty-cart.filter {
    width: auto;
    height: 29.5px;
    color: #717171;
    border-color: #d5d5d5;
    font-size: 12px;
    line-height: 1.30;
    font-weight: 600;
    padding: 2px 15px;
}

.btn.buttn.req {
    width: auto;
    min-height: 29px;
    /* height: 29px; */
    /* line-height: 1.3; */
    font-size: 12px;
    font-weight: 600;
    /* padding: 4px 15px; */
}

.btn.cta-btn.buttn.req {
    padding: 4px 11px;
}

.btn.cta-btn.buttn.req .plus-sign.req-btn {
    font-size: 17px;
}

.rating-box {
    width: 36px;
    height: 15px;
    padding: 0.8px 3.5px 1.4px 3.4px;
    border-radius: 2px;
    background-color: #357f2f;
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    margin-right: 11px;
}

.rating-box i {
    color: #fff;
    font-size: 8px;
    margin-left: 1.5px;
}

.rating-box.vendor-select {
    margin-right: 0;
}

.rating-box.vendor-select.no-ratings {
    background: #4a89dc;
    color: #fff;
    min-width: 70px;
    max-width: 70px;
    display: inline-block;
}

.btn.btn-outline-default.default {
    border-radius: 2px;
    margin-right: 15px;
    margin-left: 0;
    height: 36px;
    margin-top: -1px;
    font-size: 14px;
    font-weight: 500;
}

.btn.btn-secondary.default {
    border-radius: 2px;
    margin-right: 15px;
    margin-left: 0;
    height: 36px;
    margin-top: -1px;
    font-size: 14px;
    font-weight: 500;
}

.btn.btn-outline-default.default.rfq {
    padding: 0 15px;
    line-height: 35px;
}

.custom-checkbox.custom-checkbox-remove {
    padding-left: 0;
    font-size: 10px;
    line-height: 1.5;
    letter-spacing: 0;
    padding-top: 30px;
}

.custom-checkbox.custom-checkbox-remove .shape {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.table.req-table tr td.qty-padd {
    padding: 0 10px;
}

.quantity .valbox {
    background: #fff;
    color: #000;
    text-align: center;
    box-shadow: none;
    outline: none;
    margin: 0 2px;
    border-radius: 6px;
    border: solid 0.5px #aecef7;
    padding: 1px ​2px 0px;
    height: 24px;
    max-width: 46px;
    font-size: 12px;
    letter-spacing: 0.3px;
}

.qty-padd .quantity .valbox, .qty-td .quantity .valbox,.qty-td2 .quantity .valbox {
    max-width: 46px;
}


.full-table td.qty-div {
    padding: 0 8px 0 18px;
}

.form-container.bg-light-gray.padd {
    padding: 13px;
}

.form-container.bg-light-gray.padd2 {
    padding: 25px 21px;
}

.form-container.bg-light-gray.padd .data-table {
    margin-bottom: 0;
}

.full-table td .form-control.remark {
    border: 1px solid #c9c9c9;
    box-shadow: none;
    color: #9d9d9d;
    border-radius: 2px;
}

.req-heading {
    display: flex;
    justify-content: space-between;
}

.container-height {
    min-height: 190px;
}

.viewdetail-box {
    margin-bottom: 35px;
}

.viewdetail-box.order {
    margin-bottom: 20px;
    padding-left: 25px;
}

.viewdetail-box.remove-margin {
    margin-bottom: 0;
}

.viewdetail-box .form-control.field {
    box-shadow: none;
    border-radius: 2px;
    height: 38px;
}

.filters.inline-block,
.filters.inline-block .form-inline,
.pull-right.custom-pull-right {
    display: flex;
    align-items: flex-end;
}

.quick-actions {
    width: 65px;
    height: 26px;
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(198, 198, 198, 0.08);
    border: solid 0.5px #ccc;
    background-color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.45;
    letter-spacing: 0.12px;
    color: #272727;
    padding: 4px 5px 4px 6px;
    display: inline-block;
    position: relative;
   /*  margin-left: 6px; */
}

.quick-multiple {
    height: 26px;
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(198, 198, 198, 0.08);
    border: solid 0.5px #ccc;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.45;
    letter-spacing: 0.12px;
    color: #272727;
    padding: 4px 5px 4px 6px;
    display: inline-block;
    position: relative;
    margin-left: 6px;
}
.quick-actions.download {
    width: 148px;
    line-height: 1.5;
}

.quick-actions:hover,
.quick-actions:focus,
.quick-actions:active {
    color: #272727;
    text-decoration: none;
}

.quick-actions span,
.quick-actions .download-img {
    position: absolute;
    top: 27%;
    transition: 0.2s all ease;
    right: 7px;
}

.quick-actions .download-img {
    width: 10px;
}

.more-actions.open .quick-actions span {
    transform: rotate(180deg);
}

.more-actions .quick-actions {
    padding-left: 10px;
}

.form-group.custom-form-group.cross-icon .close,
.cross-icons .close,
.cross-cat-icon .close {
    font-size: 11px;
    font-weight: 500;
    position: absolute;
    right: 30px;
    top: 30px;
    outline: none;
}

.form-group.custom-form-group.cross-icon .close.shift,
.cross-icons .close {
    font-size: 12px;
    top: 18px;
}

.cross-cat-icon .close.shift {
    right: 60px;
    top: 42px;
}

.form-group.custom-form-group.cross-icon .close.shift.comparison {
    top: 15px;
}

.form-group.custom-form-group.cross-icon.date .close.shift.comparison {
    top: 31.5px;
    color: #777;
}

.search-user.search {
    position: relative;
    display: inline-block;
}

.search-user .form-control.search-port {
    height: 32px;
    padding-right: 30px;
}

.search-user .find-btn.cross-btn.cat.search {
    position: absolute;
    right: 6px;
    border: 0;
    background: none;
    color: #888;
    top: 3px;
    font-size: 16px;
    outline: none;
    opacity: 0;
    pointer-events: none;
}

.search-user.showCross .find-btn.cross-btn.cat.search {
    opacity: 1;
    pointer-events: all;
}

.search-user .find-btn.cross-btn.cat.search.order {
    top: 0;
    bottom: 0;
}

.viewdetail-box .logo {
    width: 28%;
    margin-top: 8px;
}

.viewdetail-box .logo img {
    border-radius: 6px;
}

.product-img.logo {
    height: 150px;
    border-radius: 5px;
}

.viewdetail-box .logo.box {
    position: relative;
    display: inline-block;
}

.viewdetail-box.logo .logo {
    width: auto;
}

.viewdetail-box .logo.box .close.cross-butn {
    position: absolute;
    top: -11px;
    right: -11px;
    border: 1px solid #444;
    border-radius: 50px;
    width: 22px;
    height: 22px;
    background: #ccc;
    opacity: 1;
}

.viewdetail-box.logo .logo.box .close.cross-butn {
    top: -5px;
}

.viewdetail-box .logo.box .close.cross-butn.light span {
    line-height: 0;
    font-size: 22px;
    position: relative;
    top: -3px;
    color: #000;
}

.datatable-body .ng-select.form-control.inventory {
    width: 140px;
}

.btn.empty-cart.filter:disabled {
    opacity: 0.5;
}

.ng-select,
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option span.ng-option-label {
    text-transform: capitalize;
}

.buttn.place.inventory {
    width: 130px;
    position: relative;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
    background: #fff;
    border-color: #e8e8e8;
    color: #333;
    outline: none;
}

.form-control.shipCaptain-dropdown.ng-select.ng-select-disabled {
    min-height: 45px;
    background: #ebebeb;
    padding-left: 12px;
    padding-top: 9px;
}

.form-control.shipCaptain-dropdown.ng-select.ng-select-disabled .ng-value-container .ng-value {
    font-size: 16px;
}

.more-actions .dropdown-menu .dropdown-item.disabled-option {
    pointer-events: none;
    background: #ffe2e2;
}

.table.req-table td a.add-items.buttn.place {
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    color: #fff;
}

label .pipe-seperator {
    position: relative;
    top: -1px;
    font-weight: 400;
}

.rightblock.info-date {
    cursor: pointer;
    margin-left: 8px;
    top: -1px;
}

.rightblock.info-date.rmargin10 {
    margin-left: 0;
    top: 5px;
}

.rightblock.info-date img {
    opacity: 0.9;
}

.rightblock.info-date .info-text {
    font-size: 12px;
    color: #444;
    padding: 5px 10px;
    position: absolute;
    right: 0px;
    bottom: 20px;
    background-color: #fff;
    border-radius: 3px;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    width: 200px;
    transition: all 0.3s ease 0s;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.rightblock.info-date.rmargin10 .info-text {
    bottom: 30px;
}

.rightblock.info-date:hover .info-text {
    visibility: visible;
    opacity: 1;
}

.full-table.smallTag td small {
    font-size: 12px;
    line-height: 18px;
}

.full-table.smallTag td.subcat-td small {
    position: relative;
    top: 2px;
    color: #888;
}

.form-control.remark:disabled {
    background: #fff;
}

.port-country-dropdown.ng-select {
    width: 190px;
}
.port-country-dropdown.ng-select .ng-clear-wrapper {
    right:-10px;
    top:0.5px;
}

.modal-dialog.modal-lg.inventory .modal-content,.modal-lg.catalogue .modal-content {
    padding: 0 20px;
}

.modal-dialog.modal-lg.inventory .modal-content .padding20,.modal-lg.catalogue .modal-content .padding20 {
    padding: 20px 0;
}

.modal-dialog.modal-lg.inventory .modal-content .add-product.padding20,.modal-lg.catalogue .modal-content .add-product.padding20 {
    padding: 0 0 20px;
}

.note-color {
    color: #a3a3a3;
    font-weight: 500;
}

.card-header .loading-spinner.small {
    position: absolute;
    right: 26px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.ship .card-header .loading-spinner.small {
    top: -6px;
}

.card-header .loading-spinner.small {
    width: 1.5em;
    height: 1.5em;
}

.download-option.dropdown {
    pointer-events: none;
}

.download-option.dropdown .more-dots,
.download-option.dropdown .more-dots::before,
.download-option.dropdown .more-dots::after {
    background: #a8a8a8;
}

.pagination-wrap {
    display: flex;
    width: 230px;
    justify-content: space-between;
}

.page-box {
    width: 100px;
    height: 25px !important;
    position: relative;
    top: -5px;
    padding: 6px;
    font-size: 12px;
}

.order-head {
    background: #f0f8ff;
    display: flex;
    margin-bottom: 22px;
    border: 1px solid #ededed;
}

.order-head .inner-head {
    width: 25%;
    border-right: 1px solid #e6e6e6;
    text-align: center;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 0px;
}

.order-head .inner-head:last-child {
    border-right: 0;
}

.order-head .inner-head p {
    color: #808080;
    font-size: 14px;
    margin-bottom: 2px;
    line-height: 16px;
}

.order-head .inner-head p:last-child {
    color: #000;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 19px;
    word-break: break-word;
    white-space: break-spaces;
    position: relative;
}

.order-head .inner-head .thin-font.status-box {
    width: auto;
    position: static;
}

.order-head .inner-head .thin-font.status-box .status-tag {
    font-size: 13.5px;
    line-height: 22px;
    margin-left: 0;
    margin-right: 5px;
    height: 24px;
    border-radius: 2px;
    padding: 0 7px;
    min-width: 165px;
}

.order-head .inner-head .thin-font.status-box.order .status-tag {
    text-transform: uppercase;
}

.order-head .inner-head p:last-child a {
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    position: absolute;
    top: 2px;
    white-space: nowrap;
}

.order-details-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.order-details-wrap .form-container.bg-light-gray {
    width: 74.4%;
    padding: 22px 25px 30px;
}

.order-details-wrap .form-container.bg-light-gray:last-child {
    width: 24%;
}
.order-details-wrap .form-container .head-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.order-details-wrap .details-inner .form-container .head-box {
    margin-bottom: 20px;
}

.order-details-wrap .form-container .head-box h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
}

body .viewdetail-box.order .p-inputtext:disabled {
    font-size: 14px;
    height: 19px;
    margin-top: 0;
    padding:0;
}

.viewdetail-box.order .form-control.ng-select.ng-select-disabled {
    line-height: 19px;
}

.viewdetail-box.order .form-control.ng-select.ng-select-disabled .ng-select-container {
    font-size: 14px;
}

body .viewdetail-box.order .p-inputtext {
    margin-top: 3px;
}

.buttn.empty-cart.order {
    background-color: #2585ec;
    width: auto;
    height: 24px;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
}

.quick-actions.download img,
.more-actions.order .dropdown-menu .dropdown-item img {
    margin-right: 4px;
    position: relative;
    top: -1px;
}

.more-actions.order .dropdown-menu {
    width: 190px;
}

.order-details-wrap .details-inner {
    width: 68.5%;
}

.order-details-wrap .form-container.bg-light-gray.timeline {
    width: 30%;
    padding: 20px 30px;
}

.order-details-wrap .details-inner .form-container.bg-light-gray {
    width: 100%;
    padding: 20px 25px 10px;
    min-height: auto;
}

.order-details-wrap .details-inner .form-container.bg-light-gray:last-child {
    padding: 10px 10px 12px 25px;
}

.order-details-wrap .details-inner .form-container.bg-light-gray:last-child .head-box {
    margin-bottom: 5px;
}

.order-details-wrap .form-container.bg-light-gray.timeline .head-box {
    justify-content: center;
    margin-bottom: 30px;
}

.order-details-wrap .additional-detail {
    display: flex;
    align-items: center;
}

.order-details-wrap .additional-detail .inner-content {
    padding: 15px 20px;
    border-right: 1px solid #e6e6e6;
    min-width: 240px;
}

.order-details-wrap .additional-detail .inner-content:nth-child(2) {
    min-width: 150px;
    text-align: center;
}

.order-details-wrap .additional-detail .inner-content:last-child {
    border-right: 0;
}

.order-details-wrap .additional-detail .inner-content p {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 0;
    word-break: break-word;
}

.order-details-wrap .additional-detail .inner-content p:last-child {
    color: #808080;
}

.order-details-wrap .additional-detail .inner-content p.priority {
    color: #02a112;
}

.order-details-wrap .additional-detail .inner-content p.priority.p-urgent {
    color: #ce2339;
}

.edit-box .btn.btn-primary{
    padding: 5px 8px;
    min-height: 28px;
    font-size: 13px;
    letter-spacing: 0.5px;
}

table.table.full-table.req-table.price.update td .add-price,
table.table.full-table.req-table.price.update td .form-control.hsn-field {
    width: 100%;
    margin-inline: auto;
}

table.table.full-table.req-table.price.update td .add-price .currency-code {
    top: 8px;
    z-index: 1;
}

table.table.full-table.req-table.price.update td .add-price .currency-code.tax-field {
    left: 84.5%;
}

table.table.full-table.req-table.price.update td .form-control.remark-field {
    width: 85%;
    text-align: center;
}

table.table.full-table.req-table.price.update td .form-control.hsn-field,
table.table.full-table.req-table.price.update td .form-control.remark-field {
    padding: 3px 5px;
}

.quote.invoice .modal-body.padding20 {
    padding: 20px 0;
}

.quote h4.sure-font.bmargin20 {
    font-size: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
    text-transform: uppercase;
    font-weight: 500;
}

.quote button.close.pull-right {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.8rem 1rem;
}

.quote .form-group label {
    margin-bottom: 2px;
}

.pad-left-right25 {
    padding: 0 25px;
}

.quote .form-subhead {
    display: flex;
    background: #e8e8e8;
    padding: 5px 25px;
    margin-bottom: 18px;
    align-items: center;
}

.quote .form-subhead h3,
.quote .hsn-sac-details {
    margin-bottom: 0;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 24px;
}

.quote .btn.addChargesbtn {
    padding: 0 10px;
    height: 30px;
    border-radius: 2px;
    min-height: 30px;
    letter-spacing: 0.3px;
}

.quote .form-subhead .btn.addChargesbtn {
    height: 28px;
    min-height: 28px;
}

.pad-left-right25 .data-table.products {
    margin-bottom: 0;
}

.quote .hsn-sac-details {
    margin-bottom: 5px;
}

.seperate-charges {
    border-top: 1px solid #e8e8e8;
    padding-top: 20px;
}

.seperate-charges.remove {
    border-top: 0;
    padding-top: 0;
}

.text-left.top-margin {
    margin-top: 25px;
}

.table.full-table.req-table.price.update.invoice tr td {
    background: #fff;
    height: 45px;
}

table.table.full-table.req-table.price.update {
    padding-right: 3px;
    padding-bottom: 3px;
}

.data-table.products .scroll-table::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
    height: 4px;
}

.data-table.products.scrollbar .scroll-table::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
}

.data-table.products.scrollbar .scroll-table::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 3px;
}

.data-table.products.scrollbar .scroll-table::-webkit-scrollbar-thumb:hover {
    background: #888;
}
.table.full-table.req-table.price.update.invoice tr th {
    font-size: 13px;
}

.table.full-table.req-table.price.update.invoice tr td,
.table.full-table.req-table.price.update.invoice tr td a,
.table.full-table.req-table.price.update.invoice tr td a~b {
    font-size: 12px;
    line-height: 15px;
}

.table.full-table.req-table.price.update.invoice tr td a~b {
    font-size: 11px;
}

.table.full-table.req-table.price.update.invoice tr td {
    padding: 5px 15px;
}

.form-group.hsn-sac {
    text-align: center;
    margin-bottom: 0;
}

.quote.invoice .form-group .error {
    font-size: 12px;
}

.knobs, .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.button {
    position: relative;
    top: 50%;
    width: 96px;
    height: 27px;
    margin: 0 auto;
    overflow: hidden;
    outline: none;
}

.button.b2 {
    border-radius: 2px;
}

.button.b2.switch {
    top:0;
    border-radius: 20px;
}

.checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
    outline: none;
}

.knobs {
    z-index: 2;
}

.layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
}

#button-16 .knobs:before {
    content: 'Active';
    position: absolute;
    top: 4.5px;
    left: 4px;
    width: 52px;
    height: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    line-height: 0;
    letter-spacing: 0.2px;
    padding: 9px 1px;
    background-color: #03A9F4;
    border-radius: 2px;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

#button-16 .checkbox:active + .knobs:before {
    width: 46px;
}

#button-16 .checkbox:checked:active + .knobs:before {
    margin-left: -26px;
}

#button-16 .checkbox:checked + .knobs:before {
    content: 'Inactive';
    left: 40px;
    background-color: #F44336;
}

#button-16 .checkbox:checked ~ .layer {
    background-color: #fcebeb;
}

.button.b2.switch#button-16 .knobs:before {
    content: 'No';
    border-radius: 20px;
    left: 40px;
    background-color: #F44336;
}

.button.b2.switch#button-16 .checkbox:checked + .knobs:before {
    content: 'Yes';
    left: 4px;
    background-color: #03A9F4;
}

.button.b2.switch .layer {
    background-color: #fcebeb;
}

.button.b2.switch#button-16 .checkbox:checked ~ .layer {
    background-color: #ebf7fc;
}

.button.b2.switch#button-16 .checkbox:checked:active + .knobs:before {
    margin-left: 0;
}

.quote .form-group .form-control.terms {
    padding-left: 10px;
}

.quote .form-group .form-control,
.quote .form-group .invoice-date .p-inputtext,
.quote .form-group .invoice-date .p-inputtext:enabled,
.quote .form-group .invoice-date .p-inputtext:enabled:hover:not(.ui-state-error),
.quote .form-group .invoice-date .p-inputtext:enabled:focus:not(.ui-state-error) {
    height: 40px;
}

.quote .form-group textarea.form-control {
    height: 90px;
}

.quote .form-group .form-control.email-tag {
    height: auto;
    min-height: 40px;
}

.quote .add-price .currency-code {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    font-weight: 400;
    color: #777;
    height: 100%;
    width: 35px;
    line-height: 40px;
    z-index: 1;
    padding-left: 7px;
}

.expired-products-head .download-products,
.vendor-td .download-products {
    position: absolute;
    right: 6px;
    top: 0;
    bottom: 0;
    border: 0;
    outline: none;
    font-size: 6px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.3px;
    text-align: center;
    color: #000000;
    background: none;
    width: 45px;
}

.vendor-td .download-products {
    left: 0px;
    top: 2px;
    right: unset;
    bottom: unset;
    width: 23px;
    padding: 0;
}

.expired-products-head .download-products>span,
.vendor-td .download-products>span {
    width: 19px;
    height: 19px;
    background: #fff;
    border-radius: 50px;
    font-size: 9.5px;
    text-align: center;
    display: block;
    padding-top: 5px;
    margin: 0 auto 2px;
}

.vendor-td .download-products>span {
    border: 1px solid #f2f2f2;
    padding-top: 4px;
}

.price.product-details {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 11px 15px 15px;
    box-sizing: border-box;
    margin-bottom: 34px;
}

.price.product-details .head {
    font-size: 18px;
    margin-bottom: 10px;
}

.price.product-details .detail-content {
    display: flex;
}
.price.product-details .detail-content .img-box {
    width: 136px;
    height: 131px;
    min-width: 136px;
    margin-right: 58px;
    text-align: center;
    border: 1px solid #ebebeb;
    padding: 2px;
}

.price.product-details .detail-content .img-box img {
    width: 100%;
    height: 100%;
}

.price.product-details .content-box p {
    color: #7a7a7a;
    font-size: 14px;
    margin-bottom: 13px;
    line-height: 16px;
}
.price.product-details .content-box p.remove-margin {
    margin-bottom: 0;
}

.price.product-details .content-box p span {
    white-space: break-spaces;
    display: block;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    word-break: break-word;
    color: #000;
}

.price.product-details .content-box p span.p-name {
    color: #2191f7;
}

.price.product-details .detail-content .img-box.no-border {
    border: 0;
}

.table.full-table.req-table.price.update.history {
    min-width: 500px;
}

.table.req-table.price.update.history tr th {
    text-align: center;
    height: 33px;
}
.table.req-table.price.update.history tbody tr td {
    height: 34px !important;
    background: #fff;
    text-align: center;
    border-bottom: 0;
}

.table.req-table.price.update.history tbody tr:last-child td {
    border-bottom: 1px solid;
}

.table.req-table.price.update.history th,
.table.req-table.price.update.history tr td,
.table.req-table.price.update.history tr th:last-child,
.table.req-table.price.update.history tr td:last-child {
    border-color: #d9d9d9 !important;
}

.table.req-table.price.update.history th:nth-child(2),
.table.req-table.price.update.history th:nth-child(3) {
    min-width: auto !important;
}

.date-box > span,
.comparison-inner .card-body .form-group > span,
.custom-form-group.cross-icon.date > span {
    position: absolute;
    top: 6px;
    z-index: 1;
}

.date-box > span {
    top: 4px;
}

.comparison-inner .card-body .form-group > span {
    top: 10px;
    right: 25px;
}

.comparison-inner .card-body .form-group > span img {
    width: 20px;
    height: 20px;
}

.date-box > span img {
    width: 18px;
    height: 18px;
}

.custom-form-group.cross-icon.date > span {
    top: 24px;
    left: 22px;
}

.custom-form-group.cross-icon.date > span img {
    width: 22px;
    height: 22px;
}

.comparison-inner .card-body .ng-select {
    font-size: 14px;
    border: 1px solid #d9d9d9;
    min-height: 39px;
    border-radius: 1px;
}

.comparison-inner .card-body .ng-select .ng-select-container {
    height: auto;
    min-height: 39px;
    padding-right: 8px
}
.comparison-inner .card-body .data-table .form-control.ng-select {
    min-height: 35px;
}
.comparison-inner .card-body .data-table .form-control.ng-select .ng-select-container {
    padding: 5px 8px;
    min-height: 35px;
}

.comparison-inner .card-body .data-table .form-control.ng-select .ng-select-container .ng-value-container .ng-input {
    top: 6px;
}

.select-file.upload {
    padding: 0 0 0 8px;
    height: 34px;
    border-right: 0;
    line-height: 33px;
    color: #686868;
    width: 220px;
    border: 1px solid #cecece;
    border-right: 0;
}

.select-file.upload img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    position: relative;
    top: -1px;
}

.select-file.upload input {
    cursor: pointer;
}

.select-file.upload .btn-span {
    left: unset;
    right: 0;
    top: 0;
    padding: 0px 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: #5b5b5b;
    background: #bebebe;
    font-weight: 500;
}

.select-file.upload ~ .filename {
    color: #686868;
    font-size: 12px;
    font-style: italic;
    line-height: 14px;
    word-break: break-word;
}

.status-tags.awaiting,
.status-tags.approved,
.status-tags.rejected {
  font-weight: 600;
}

.status-tags.approved {
  color: #128200;
}

.status-tags.awaiting {
  color: #e1b518;
}

.status-tags.rejected {
  color: #e40000;
}

.price-heaad {
    text-align: center;
    padding-top: 12px;
    margin-bottom: 20px;
}

.price-heaad .show-message {
    background: #f7f7f7;
    max-width: 800px;
    width: 100%;
    margin-inline: auto;
    padding: 40px;
    margin-top: 10px;
}

.price-heaad .show-message h2 {
    margin-bottom: 20px;
    text-transform: capitalize;
}

.price-heaad .show-message.font {
    background: #fff;
}

.price-heaad .show-message.font h2 {
    font-size: 28px;
}

.placeholder.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    z-index: 0;
}

.form-group.kyc-field .form-control, .table-field,
.form-group.popup-field .form-control {
    border: 1px solid #cacaca;
    border-radius: 0;
    height: 33px;
}

.form-group.popup-field .form-control,
.searchbox.popup-search .ng-autocomplete .p-autocomplete .p-inputtext {
    height: 37px;
}

.form-group.popup-field.dropdown-box .form-control {
    height: 40px;
}

.searchbox.popup-search.errors .ng-autocomplete .p-autocomplete .p-inputtext {
    background: #fff0f0 !important;
}

.popup-field .new-dropdown.ng-select .ng-select-container {
    height: 35px;
    min-height: 35px;
}

.mobile-prefix .new-dropdown.ng-select .ng-select-container,
.popup-field .new-dropdown.ng-select .ng-select-container {
    padding-right: 0;
}

.mobile-prefix .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 5px;
}

.mobile-prefix .ng-dropdown-panel.ng-select .ng-select-container .ng-value-container .ng-input {
    padding: 0 25px 0 8px;
}

.mobile-prefix .new-dropdown.ng-select .ng-select-container .ng-value-container .ng-input>input {
    width: 30px;
}

.form-group.kyc-field .new-dropdown.multiple.ng-select {
    min-height: 33px;
    height: auto;
}

.kyc-input.form-control.ng-select.ng-select-disabled .ng-select-container,
.popup-field .new-dropdown.form-control.ng-select.ng-select-disabled .ng-select-container {
    font-size: 14px;
    padding-left: 10px;
    line-height: 29px;
}

.popup-field .new-dropdown.form-control.ng-select.ng-select-disabled .ng-select-container {
    color: #495057;
}

.form-control[readonly]:focus,
.kyc-input.form-control.ng-select.ng-select-disabled,
.popup-field .new-dropdown.form-control.ng-select.ng-select-disabled .ng-select-container {
    background: #e9ecef;
}

.search-categories.search-field .searchbox.popup-search.background .ng-autocomplete .p-autocomplete .p-inputtext {
    background: #e9ecef !important;
    color: #495057;
}

.modal-dialog.gray.modal-lg.inventory .modal-content {
    padding: 0;
}

.sure-font.text-center {
    position: relative;
}

.sure-font.text-center .popup-close {
    background: #000;
    color: #fff;
    right: 0;
}

.form-group .bold-label {
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 2px;
    font-size: 14px;
}

.submit-btn-box .buttn.place.save,
.submit-btn-box .btn.empty-cart.back,
.buttn.place.save.inventory,
.btn.empty-cart.back.inventory {
  width: 176px;
  height: 34px;
  font-size: 15px;
  background: #0074f2;
  border-color: #0074f2;
  font-weight: 400;
  transition: all 0.15s ease;
}

.submit-btn-box .btn.empty-cart.back,
.btn.empty-cart.back.inventory {
  width: 84px;
  margin-right: 20px;
  background: #848e98;
  border-color: #848e98;
  color: #fff;
  margin-left: 0;
}

.buttn.place.save.inventory,
.btn.empty-cart.back.inventory {
  width: 100px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

.btn.empty-cart.back.inventory {
    width: 82px;
    margin-right: 10px;
    background: transparent;
    border-color: #c9c9c9;
    color: #686868;
}

.submit-btn-box .buttn.place.save:hover,
.submit-btn-box .buttn.place.save:focus,
.buttn.place.save.inventory:hover,
.buttn.place.save.inventory:focus {
  background: #036adb;
  border-color: #036adb;
}

.submit-btn-box .btn.empty-cart.back:hover,
.submit-btn-box .btn.empty-cart.back:focus,
.btn.empty-cart.back.inventory:hover,
.btn.empty-cart.back.inventory:focus {
  background: #767f89;
  border-color: #767f89;
}

.btn.empty-cart.back.inventory:hover,
.btn.empty-cart.back.inventory:focus {
  color: #fff;
}

.sure-font.text-center.font22 {
    font-size: 22px;
}

.hide-cross-btn.rfq-subcatlist.placeholder.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    z-index: 0;
}
.order-timeline li {
    /* list-style: none; */
    /* line-height: 19px; */
    display: -webkit-box;
    /* display: inline-block; */
}

.more-actions.date-dropdown .quick-actions {
    width: 278px;
    height: 42px;
    border-radius: 2px;
    background: #ededed;
    border: 0;
    padding-top: 10px;
    font-size: 15px;
    color: #000;
}

.more-actions.date-dropdown .quick-actions > img {
    margin-right: 5px;
    position: relative;
    top: -2px;
}

.filter-tag-box span a,
.more-actions.date-dropdown .quick-actions .reset-icon {
    position: absolute;
    top: 0;
    right: 8px;
    margin: auto;
    bottom: 0;
    width: 16px;
    height: 16px;
    font-size: 10px;
    color: #f3f3f3;
    background: #868686;
    border-radius: 50px;
    text-align: center;
    line-height: 18px;
}

.filter-tag-box span a {
    position: relative;
    top: -1px;
    right: 0;
    display: inline-block;
}

.more-actions.date-dropdown .quick-actions .reset-icon {
    width: 13px;
    height: 13px;
    right: 23px;
    top: 0;
    font-size: 8px;
    line-height: 14px;
}

.more-actions.date-dropdown .quick-actions span {
    top: 34%;
}

.inner-list.dropdown-inner {
    top: 0;
    width: 278px;
    visibility: visible;
    opacity: 1;
    background: transparent;
    position: static;
    border-radius: 0;
    box-shadow: none;
}

.inner-list.dropdown-inner li > a {
    font-size: 15px;
}

.inner-list.dropdown-inner li:last-child > a {
    padding: 6px 15px;
}

.filter-options.top.innerList {
    top: 70px;
    width: 278px;
    right: 10px;
    border-radius: 2px;
    padding: 5px 0;
    pointer-events: none;
}

.stats-container.pad .filter-options.top.innerList {
    right: 234px;
}

.filter-options.top.innerList.show {
    top: 65px;
    pointer-events: all;
}

.filter-options.top.filter {
    top: 65px;
    right: 15px;
}

.tabular-detail.data-table.batch.remove-margin {
    margin-bottom: 0;
}

.scroll-table table.table.full-table.req-table.price.update.new.selling {
    min-width: 900px;
}
.scroll-table table.table.full-table.req-table.price.update.new.selling.small-minwidth {
    min-width: 600px;
}
.scroll-table table.table.full-table.req-table.price.update.new.remove-minwidth {
    min-width: unset;
}

.modal-dialog.gray.modal-md.product-suggestion {
    max-width: 650px;
}

.discount-box {
    display: flex;
    justify-content: space-between;
    max-width: 140px;
   /*  margin-inline: auto; */
}

.discount-box .form-control.discount-input {
    height: 35px;
    margin-right: -1px;
    padding-left: 7px;
    border-radius: 2px 0 0 2px;
    box-shadow: none;
    border-right: 0;
}

.discount-box .form-control.discount-input:focus {
    background-color: #fff;
}

.edit-quantity-icon {
    position: relative;
    top: -2px;
    width: 14px;
    height: 14px;
    display: inline-block;
    text-align: center;
}

.edit-quantity-icon > img {
    width: 10.5px;
}

.buttn.discount.close,
.buttn.discount.grey {
    background: transparent;
    padding: 5px 7px;
    color: #000;
    outline: none;
}

.buttn.discount.grey {
    background: #cacaca;
    padding: 5px 11px;
}

.buttn.discount.grey > i {
    font-size: 13.5px;
}

.form-control.read-field {
    background: #e9ecef;
    padding: 0.375rem 0.75rem;
    height: 45px;
    line-height: 32px;
}

.inner-list1{
    width: 100%;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    padding: 0;
    box-shadow: 0 2px 5px rgb(0 0 0 / 5%);
    position: absolute;
    z-index: 99;
}

.inner-list1 li {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 8px;
}

.inner-list1 li:hover {
  background: #f7f7f7;
}

.quote-td .inner-list1.outer,
.btn.reqQuotation .inner-list1.outer {
  width: 100%;
  top: 30px;
  right: 0;
}

.btn.reqQuotation .inner-list1.outer {
    top: 40px;
}

.btn.reqQuotation .inner-list1.outer li {
    text-align: left;
    padding: 11px 10px 11px 15px;
}

.btn.reqQuotation .inner-list1.outer > li > a {
    color: #777;
    text-decoration: none;
}

.quote-td .buttn.req.export:hover .inner-list1.outer,
.btn.reqQuotation:hover .inner-list1.outer {
  visibility: visible;
  opacity: 1;
}

.product-img.prod-img, .attach {
    width: 100%;
    object-fit: contain;
    justify-content: center;
    border: 1px solid #d7d7d8;
    border-radius: 7px;
    height: 140px;
}

.btn.reqQuotation {
    min-height: 39px;
    position: relative;
}

.select-requirement .ng-dropdown-panel .requirement-name-span,
.select-requirement .ng-value-container .ng-value .ng-value-label > b {
    max-width: 185px;
    display: inline-block;
    vertical-align: bottom;
    text-overflow: ellipsis;
    overflow: hidden;
}

.select-requirement .ng-value-container .ng-value .ng-value-label > b {
    max-width: 164px;
}

/* Kyc Detail & Cart Accordion CSS */
.tabs {
    border-radius: 0;
    overflow: hidden;
    /* box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5); */
}

.tab {
    width: 100%;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid #adadad;
}

.tab-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.1em 1.3em;
    cursor: pointer;
    margin-bottom: 0;
    color: #0057b5;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
}
/* .tab-label:hover {
    background: #f4f4f4;
} */
.tab-label::after {
    content: "";
    width: 15px;
    height: 8px;
    text-align: center;
    transition: all 0.35s;
    border-bottom: 8px solid #000;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    transform: rotate(180deg);
}
.tab-content {
    max-height: 0;
    padding: 0 1.5em;
    color: #000;
    background: white;
    transition: all 0.3s;
}

.tabs > .tab > input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

/*.tabs > .tab > input:checked + .tab-label {
    background: #f4f4f4;
} */
.tabs > .tab > input:checked + .tab-label::after {
    transform: rotate(0deg);
}
.tabs > .tab > input:checked ~ .tab-content {
    max-height: initial;
    padding: 0.6em 1.5em 2em;
}

.tab-info .tabs > .tab .tab-label {
    padding: 0.6em 1.1em 1.2em;
    position: relative;
}

.tab-info .tabs > .tab > input:checked ~ .tab-content {
    padding: 0.2em 1.2em 1.0em;
}
.tab-info .tabs > .tab > input:checked ~ .tab-content .remove-section {
    opacity: 1;
    visibility: visible;
}

.tab-info .tabs > .tab .tab-label::after {
    width: 12px;
    height: 7px;
    transition: all 0.30s;
    border-bottom: 7px solid #928d8d;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-radius: 1px;
    margin-top: 10px;
}

.tab-info .tabs > .tab {
    border-bottom: 1px solid #d5d5d5;
}

.tab-info .tabs > .tab:last-child {
    border-bottom: none;
}

.fullbox .form-container {
    border-radius: 1px;
}

label.box-headfont.custom-checkbox.category .shape {
    width: 15px;
    height: 15px;
}

label.box-headfont.custom-checkbox.category .shape::after {
    left: 1.1px;
}


@media (max-width:1450px) {
    .order-details-wrap .additional-detail .inner-content {
        padding: 15px;
        min-width: 190px;
    }
}

@media (max-width:1350px) {
    .card.new-card.rfq-count {
        margin-left: 0;
    }
}

@media (max-width:1300px) {
    .card.new-card.rfq-count {
        max-width: 230px;
    }

    .card.new-card.rfq-count .card-header {
        padding: 19px 11px 17px 10px;
    }

    .card.new-card.rfq-count .new-body .count {
        font-size: 52px;
    }

    .card.new-card.rfq-count .new-body {
        padding: 56px 10px 20px;
    }
}
@media (max-width:1255px) {
    .order-head .inner-head p:last-child a{
        display: contents;
    }
    .order-details-wrap .additional-detail .inner-content{
        min-width: 150px;
    }
    .quick-actions {
        margin-left: 0;
    }
}
@media (max-width:1155px) {
    .card.new-card.vendor {
        width: 100%;
    }
}
@media (max-width:1300px) and (min-width:996px) {
    /* .order-timeline .not-done .divider.blue-bg {
        background-color: #e8e8e8;
        position: relative;
        left: -113px;
    } */

    .order-timeline li {
        list-style: none;
        line-height: 19px;
        display: -webkit-box;
        display: flex;
    }

    .order-timeline li p.date {
        width: 114px;
        padding-right: 6px;
        margin-left: 3px;
        font-size: 11px;
        position: absolute;
        padding-top: 19px;
        left: 20px;
    }
    .order-details-wrap .additional-detail .inner-content{
        min-width: 150px;
    }
    /* .order-timeline li p {
        margin-left: 6px;
        font-size: 12px;
        padding-left: 15px;
    }
    .order-timeline li p {
        display: inline-block;
        margin-left: 10px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
    } */
}
@media (max-width:992px){
    .order-head .inner-head p:last-child a{
        display: contents;

    }
    .order-details-wrap .additional-detail{
        display: flex;
    }
    .order-details-wrap .additional-detail .inner-content:nth-child(2) {
        text-align: left;
    }
    .order-details-wrap .form-container.bg-light-gray.timeline,.order-details-wrap .details-inner .form-container.bg-light-gray
    ,.order-details-wrap .details-inner{
        width: 100%;
    }
    .filter-options.top.filter {
        top: 105px;
        right: unset;
    }
    .stats-container.pad .filter-options.top.innerList,
    .filter-options.top.innerList {
        right: unset;
    }
    .filter-options.top.innerList.show {
        top: 100px;
    }
    .close-filter-btn {
        right: 10px;
        top: 10px;
    }
    .sub-head-inner.subheading {
        display: inline-block;
    }
    .purchase-product-count {
        height: auto;
        margin-right: 25px;
        margin-bottom: 20px;
    }
    .sub-head-inner.subheading.purchase {
        font-size: 24px;
        padding-top: 10px;
        display: block;
    }
}
@media (max-width: 767px) {
    .form-container,
    .form-container.pad-top-bottom,
    .form-container.bg-light-gray.padd2 {
        padding: 20px 19px;
    }
    .form-container .tmargin50 {
        margin-top: 20px;
    }
    .form-container.bmargin30,
    .primary-font.bmargin30 {
        margin-bottom: 20px;
    }
    .breadcrum.bmargin30 {
        margin-bottom: 15px;
    }
    .order-details-wrap .details-inner {
        width: 100%;
    }
    .order-details-wrap .details-inner .form-container.bg-light-gray{
        width: 100%;
    }
    .order-details-wrap .form-container.bg-light-gray.timeline {
        width: 100%;
    }
    .order-head .inner-head .thin-font.status-box .status-tag{
        min-width: 100px;
        font-size: 10px;
        height: 20px;
        line-height: 17px;
        text-align: center;
    }
    .more-actions.date-dropdown .quick-actions {
        height: 38px;
        padding-top: 8px;
    }
    .filter-options.top.innerList.show {
        bottom: unset;
        position: absolute;
    }
    .filter-options.top.filter.show {
        top: 37px;
        right: 0;
        bottom: unset;
        padding: 15px;
    }
}

@media(max-width: 599px) {
    .viewdetail-box.remove-margin,
    .viewdetail-box {
        margin-bottom: 15px;
    }
    .order-head .inner-head{
        width:50%
    }
    .order-head .inner-head .thin-font.status-box .status-tag{
        min-width: 80px;
    }
    .order-head .inner-head p{
        font-size: 12px;
    }
    .order-head .inner-head p:last-child{
        font-size: 13px;
    }
    .order-details-wrap{
        display: block;
    }
    .order-details-wrap .additional-detail{
        display: block;
    }
    .order-details-wrap .form-container.bg-light-gray:last-child{
        width: auto;
    }
    .order-details-wrap .form-container.bg-light-gray{
        width: 100%;
    }
    .order-details-wrap .additional-detail .inner-content{
        padding: 5px 20px;
        border-right: none;
    }
    .order-details-wrap .form-container .head-box h3,.buttn.empty-cart.order  {
        margin-top:10px
    }
    .linechart-container  {
        width: 500px !important;
        height: auto !important;
    }
}

@media (max-width: 479px) {
    .dashboard-data {
        display: none;
    }
    .order-head .inner-head p {
        font-size: 14px;
    }
    .order-head .inner-head{
        padding: 5px 5px;
    }
    .order-head .inner-head p:last-child{
        font-size: 14px;
    }
    .price-in{
        position: relative !important;
    }
    .order-details-wrap .form-container.bg-light-gray {
        width: 100%;
        padding: 0px 10px 0px;
    }
    .order-head {
        height: auto;
        flex-wrap: wrap;
    }
    .order-head .inner-head:first-child, .order-head .inner-head:nth-child(2) {
        border-bottom: 1px solid #e6e6e6;
    }
    .order-head .inner-head:nth-child(2) {
        border-right: 0;
    }
}


