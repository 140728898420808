.bg-lightblue {
    background-color: #022170;
}

.bg-white {
    background-color: #fff;
}

.bg-skyblue {
    background-color: #c7ddff;
}

.bg-gray {
    background-color: gray;
    color: #000;
}

.color-white {
    color: #fff;
}

.color-444 {
    color: #444;
}

.color-green {
    color: #51a251;
}

.color-lightblue {
    color: #0d62ff;
}

.text-orange {
    color: orange;
}

.color-999 {
    color: #999;
}

.color-skyblue {
    color: #c7ddff;
}

.color-000 {
    color: #000;
}

.border-violet {
    border-color: #aa5ff5;
}

.border-blue {
    border-color: #3f6ad8;
}

.border-green {
    border-color: #3ac47d;
}

.border-red {
    border-color: #ff0000;
}

.border-dark-red {
    border-color: #d92550;
}

.bg-violet {
    background-color: #aa5ff5;
}

.bg-blue {
    background-color: #3f6ad8;
}

.bg-green {
    background-color: #3ac47d;
}

.bg-red {
    background-color: #ff0000;
}

.bg-dark-red {
    background-color: #d92550;
}

.bg-light-gray {
    background-color: #fff;
}

.bg-dark-gray {
    background-color: #f5f5f5;
}

.bg-light-green {
    background-color: #e4fce4;
}

.awaiting {
    color: #5c9b43;
}

.approved {
    color: #999;
}

.out-for-delivery {
    color: #fdb300;
}

.vendor-confirm {
    color: #2dcb2d;
}

.partial-delivered {
    color: #7fd77f;
}

.rejected {
    color: #f30e0e;
}

.delivered {
    color: #008f01;
}

.status-box .status-tag.rejected {
    border-color: #f30e0e;
    background-color: #f30e0e;
}

.status-box .status-tag.awaiting {
    border-color: #5c9b43;
    background-color: #5c9b43;
}

.status-box .status-tag.out-for-delivery {
    border-color: #fdb300;
    background-color: #fdb300;
}

.status-box .status-tag.approved {
    border-color: #999;
    background-color: #999;
}

.status-box .status-tag.vendor-confirm {
    border-color: #2dcb2d;
    background-color: #2dcb2d;
}

.status-box .status-tag.partial-delivered {
    border-color: #7fd77f;
    background-color: #7fd77f;
}

.status-box .status-tag.delivered {
    border-color: #008f01;
    background-color: #008f01;
}

.status-tag.expiring {
    background: #f5893b;
}

.status-tag.expired {
    background: #d32727;
}

.status-tag.damaged {
    background: #0054af;
}

.status-tag.low-stock {
    background: #fdb300;
}