.single-box.arrow-space {
    padding-right: 120px;
}

h3 {
    font-size: 22px;
    letter-spacing: 0.5px;
    /* margin-bottom: 30px; */
}

@media (max-width: 1260px) {
    h3 {
        font-size: 20px;
        letter-spacing: 0;
    }
}

@media (max-width: 1024px) {
    .tabledata-sendaction {
        left: 0;
        padding: 10px 15px;
        height: auto;
    }
    .single-box.arrow-space {
        padding-right: 0;
    }
    .primary-font.bmargin30 {
        margin-bottom: 15px;
    }
}

@media (max-width: 768px) {
    .filter-options {
        padding: 15px;
    }
    .material.ngx-datatable .datatable-footer .page-count {
        flex: 1 1 50%;
    }
    .filter-options,
    .filter-options.show {
        top: 0;
        position: fixed;
        width: 100%;
        max-width: 100%;
        bottom: 0;
        border-radius: 0;
        padding: 50px 15px 60px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .ngx-datatable.material {
        display: inline-block;
        width: 100%;
    }
    .ngx-datatable.material .datatable-footer .datatable-pager {
        margin: 0;
    }
    .pull-right.custom-pull-right {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 0;
    }
    body .ngx-datatable .datatable-footer .datatable-footer-inner {
        display: block;
        text-align: center;
    }
    body .ngx-datatable .datatable-footer .datatable-footer-inner .paging {
        text-align: left;
        left: 0;
    }
    .search-categories {
        width: 100%;
    }
    h3 {
        font-size: 17px;
        margin-bottom: 15px;
    }
    .rs-top-space {
        margin-top: 30px;
    }
    .filters {
        position: absolute;
        top: 66px;
        left: 0;
        right: 0;
        display: flex;
        padding-top: 0;
    }
    .filters>.btn {
        flex: 1;
        margin: 0 !important;
        border-radius: 0;
        padding-left: 5px;
        padding-right: 5px;
    }
    .filters.inline-block.option>* {
        width: auto;
    }
    .filters.inline-block.option .search-categories .searchbox {
        width: auto;
    }
    .filter-options .close-filter-btn {
        background: #fff;
        color: #000;
        width: 100%;
        position: fixed;
        top: 0;
        right: 0;
        text-align: right;
        padding-right: 20px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        border-radius: 0;
        font-size: 32px;
        height: auto;
        line-height: normal;
    }
    .required-some-data {
        position: fixed;
        left: 0;
        top: 25px;
        z-index: 9;
        font-size: 12px;
        background-color: #fff;
        box-shadow: 0 6px 3px -4px rgba(0, 0, 0, 0.1);
        padding: 0 15px 4px;
        right: 0;
    }
    .filter-options .close-filter-btn::before {
        content: 'Filters';
        font-size: 16px;
        position: absolute;
        left: 15px;
        top: 10px;
        font-weight: 600;
        color: #919191;
    }
    .btn.cta-btn {
        padding: 12px 15px;
    }
    .no-product {
        line-height: normal;
    }
    .upload-product-box,
    .upload-product-box.request-place {
        margin-bottom: 0;
        height: auto;
    }
    .remove-selection-section {
        bottom: 0 !important;
        right: 15px;
    }
    .custom-checkbox.category {
        padding-left: 20px;
    }
    .custom-checkbox.category .shape::after {
        left: 1.1px;
    }
    .bmargin46 {
        margin-bottom: 30px;
    }
}

@media (max-width: 599px) {
    .change-pass .modal-body {
        padding: 20px;
    }
    .status-box {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
    }
    .status-box .thin-font,
    .status-box .status-tag {
        display: block;
        border-radius: 0;
        text-align: center
    }
    .remove-selection-section {
        position: relative !important;
        right: 0;
        width: 100%;
        justify-content: space-between;
    }
    .remove-section {
        justify-content: space-between;
    }
    .info-font {
        font-size: 16px;
        line-height: normal;
    }
    .status-box.req-status {
        top: 8px;
        position: relative;
        margin-bottom: 6px;
    }
    .status-box .status-tag {
        font-size: 11px;
        line-height: 1.5;
        margin-left: 0;
        height: 30px;
        padding: 6px 8px 5px 8px;
        border-radius: 4px;
    }
    .status-box.req-status .status-tag {
        display: inline-block;
    }
    .different-table-design .full-table th {
        font-size: 13px;
    }
    .table.req-table th {
        min-height: 50px;
        height: 50px !important;
        max-height: 50px !important;
    }
    .different-table-design .full-table th {
        font-size: 13px;
    }
    .full-table th,
    .full-table td {
        padding: 10px;
    }
    .table.req-table tr.add-p td {
        padding: 0 10px;
    }
    .size16 {
        font-size: 14px;
    }
    .buttn.place {
        width: 110px;
        height: 33px;
        font-size: 12px;
    }
    .btn.btn-outline-default.default 
    {
        margin-right: 12px;
        height: 33px;
        font-size: 12px;
        padding: 6px 10px;
        min-height: 33px;
    }

    .upload-product-box,
    .upload-product-box.request-place {
        margin-bottom: 15px;
        height: auto;
        width: 100%;
        margin-right: 0;
    }
    .upload-product-box.request-place {
        margin-bottom: 0;
    }
    .cart-header {
        margin-bottom: 10px;
        flex-direction: column;
    }
    .bmargin46 {
        margin-bottom: 20px;
    }
    .card.new-card.expenditure .card-body {
        padding-left: 20px;
        padding-bottom: 30px;
    }
}

@media (max-width: 479px) {
    /* .pager {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        padding-bottom: 10px !important;
    } */
    .modal-dialog.gray.modal-sm.small {
        max-width: auto;
    }
    .tmargin30 {
        margin-top: 20px;
    }
    .tmargin35 {
        margin-top: 25px;
    }
    .buttn.place.cart {
        width: 150px;
    }
    .box-headfont {
        font-size: 14px;
    }
    .box-headfont .product-count {
        font-size: 12px;
    }
    .form-group.req-name {
        margin-left: 10px;
    }
    .confirmation-box .cancelmodal-spc.empty {
        padding: 40px;
    }
    .confirmation-box {
        width: 90%;
    }
    .btn.cta-btn.create-req {
        width: 160px;
        height: 32px;
        padding: 9px 3px 7px;
        font-size: 13px;
    }
}

@media (max-width: 991) {
    .request-product-button {
        right: 12px;
    }
}

@media (min-width: 576px) {
    .modal-dialog.modal-lg.inventory,
    .modal-dialog.modal-lg.catalogue,
    .modal-dialog.modal-lg.price-history {
        max-width: unset;
        width: auto;
        margin: 1.75rem;
    }
}

@media (min-width: 992px) {
    .modal-dialog.modal-lg.inventory,
    .modal-dialog.modal-lg.catalogue,
    .modal-dialog.modal-lg.price-history {
        max-width: 1132px;
        margin: 1.75rem auto;
    }
    .modal-dialog.modal-lg.price-history {
        max-width: 1220px;
    }
    .modal-dialog.modal-lg.catalogue,
    .modal-dialog.modal-lg.status {
        max-width: 850px;
    }
}